/** =================== MONTSERRAT =================== **/

/** Montserrat Thin **/
@font-face {
  font-family: "Montserrat";
  font-weight: 100;
  font-style: normal;
  src: url("Montserrat-Thin.otf") format("opentype");
}

/** Montserrat Thin-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 100;
  font-style: italic;
  src: url("Montserrat-ThinItalic.otf") format("opentype");
}

/** Montserrat ExtraLight **/
@font-face {
  font-family: "Montserrat";
  font-weight: 200;
  font-style: normal;
  src: url("Montserrat-ExtraLight.otf") format("opentype");
}

/** Montserrat ExtraLight-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 200;
  font-style: italic;
  src: url("Montserrat-ExtraLightItalic.otf") format("opentype");
}

/** Montserrat Light **/
@font-face {
  font-family: "Montserrat";
  font-weight: 300;
  font-style: normal;
  src: url("Montserrat-Light.otf") format("opentype");
}

/** Montserrat Light-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 300;
  font-style: italic;
  src: url("Montserrat-LightItalic.otf") format("opentype");
}

/** Montserrat Regular **/
@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: normal;
  src: url("Montserrat-Regular.otf") format("opentype");
}

/** Montserrat Regular-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: italic;
  src: url("Montserrat-Italic.otf") format("opentype");
}

/** Montserrat Medium **/
@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  src: url("Montserrat-Medium.otf") format("opentype");
}

/** Montserrat Medium-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: italic;
  src: url("Montserrat-MediumItalic.otf") format("opentype");
}

/** Montserrat SemiBold **/
@font-face {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: normal;
  src: url("Montserrat-SemiBold.otf") format("opentype");
}

/** Montserrat SemiBold-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: italic;
  src: url("Montserrat-SemiBoldItalic.otf") format("opentype");
}

/** Montserrat Bold **/
@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  font-style: normal;
  src: url("Montserrat-Bold.otf") format("opentype");
}

/** Montserrat Bold-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  font-style: italic;
  src: url("Montserrat-BoldItalic.otf") format("opentype");
}

/** Montserrat ExtraBold **/
@font-face {
  font-family: "Montserrat";
  font-weight: 800;
  font-style: normal;
  src: url("Montserrat-ExtraBold.otf") format("opentype");
}

/** Montserrat ExtraBold-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 800;
  font-style: italic;
  src: url("Montserrat-ExtraBoldItalic.otf") format("opentype");
}

/** Montserrat Black **/
@font-face {
  font-family: "Montserrat";
  font-weight: 900;
  font-style: normal;
  src: url("Montserrat-Black.otf") format("opentype");
}

/** Montserrat Black-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 900;
  font-style: italic;
  src: url("Montserrat-BlackItalic.otf") format("opentype");
}

/** =================== MONTSERRAT ALTERNATES =================== **/

/** Montserrat Alternates Thin **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 100;
  font-style: normal;
  src: url("MontserratAlternates-Thin.otf") format("opentype");
}

/** Montserrat Alternates Thin-Italic **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 100;
  font-style: italic;
  src: url("MontserratAlternates-ThinItalic.otf") format("opentype");
}

/** Montserrat Alternates ExtraLight **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 200;
  font-style: normal;
  src: url("MontserratAlternates-ExtraLight.otf") format("opentype");
}

/** Montserrat Alternates ExtraLight-Italic **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 200;
  font-style: italic;
  src: url("MontserratAlternates-ExtraLightItalic.otf") format("opentype");
}

/** Montserrat Alternates Light **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 300;
  font-style: normal;
  src: url("MontserratAlternates-Light.otf") format("opentype");
}

/** Montserrat Alternates Light-Italic **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 300;
  font-style: italic;
  src: url("MontserratAlternates-LightItalic.otf") format("opentype");
}

/** Montserrat Alternates Regular **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 400;
  font-style: normal;
  src: url("MontserratAlternates-Regular.otf") format("opentype");
}

/** Montserrat Alternates Regular-Italic **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 400;
  font-style: italic;
  src: url("MontserratAlternates-Italic.otf") format("opentype");
}

/** Montserrat Alternates Medium **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 500;
  font-style: normal;
  src: url("MontserratAlternates-Medium.otf") format("opentype");
}

/** Montserrat Alternates Medium-Italic **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 500;
  font-style: italic;
  src: url("MontserratAlternates-MediumItalic.otf") format("opentype");
}

/** Montserrat Alternates SemiBold **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 600;
  font-style: normal;
  src: url("MontserratAlternates-SemiBold.otf") format("opentype");
}

/** Montserrat Alternates SemiBold-Italic **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 600;
  font-style: italic;
  src: url("MontserratAlternates-SemiBoldItalic.otf") format("opentype");
}

/** Montserrat Alternates Bold **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 700;
  font-style: normal;
  src: url("MontserratAlternates-Bold.otf") format("opentype");
}

/** Montserrat Alternates Bold-Italic **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 700;
  font-style: italic;
  src: url("MontserratAlternates-BoldItalic.otf") format("opentype");
}

/** Montserrat Alternates ExtraBold **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 800;
  font-style: normal;
  src: url("MontserratAlternates-ExtraBold.otf") format("opentype");
}

/** Montserrat Alternates ExtraBold-Italic **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 800;
  font-style: italic;
  src: url("MontserratAlternates-ExtraBoldItalic.otf") format("opentype");
}

/** Montserrat Alternates Black **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 900;
  font-style: normal;
  src: url("MontserratAlternates-Black.otf") format("opentype");
}

/** Montserrat Alternates Black-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 900;
  font-style: italic;
  src: url("MontserratAlternates-BlackItalic.otf") format("opentype");
}
