:root {
  --vscode-font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  --vscode-font-weight: normal;
  --vscode-font-size: 13px;
  --vscode-editor-font-family: Menlo, Monaco, "Courier New", monospace;
  --vscode-editor-font-weight: normal;
  --vscode-editor-font-size: 12px;
  --vscode-foreground: #616161;
  --vscode-disabledForeground: rgba(97, 97, 97, 0.5);
  --vscode-errorForeground: #a1260d;
  --vscode-descriptionForeground: #717171;
  --vscode-icon-foreground: #424242;
  --vscode-focusBorder: #0090f1;
  --vscode-textSeparator-foreground: rgba(0, 0, 0, 0.18);
  --vscode-textLink-foreground: #006ab1;
  --vscode-textLink-activeForeground: #006ab1;
  --vscode-textPreformat-foreground: #a31515;
  --vscode-textBlockQuote-background: rgba(127, 127, 127, 0.1);
  --vscode-textBlockQuote-border: rgba(0, 122, 204, 0.5);
  --vscode-textCodeBlock-background: rgba(220, 220, 220, 0.4);
  --vscode-widget-shadow: rgba(0, 0, 0, 0.16);
  --vscode-input-background: #ffffff;
  --vscode-input-foreground: #616161;
  --vscode-inputOption-activeBorder: rgba(0, 122, 204, 0);
  --vscode-inputOption-hoverBackground: rgba(184, 184, 184, 0.31);
  --vscode-inputOption-activeBackground: rgba(0, 144, 241, 0.2);
  --vscode-inputOption-activeForeground: #000000;
  --vscode-input-placeholderForeground: rgba(97, 97, 97, 0.5);
  --vscode-inputValidation-infoBackground: #d6ecf2;
  --vscode-inputValidation-infoBorder: #007acc;
  --vscode-inputValidation-warningBackground: #f6f5d2;
  --vscode-inputValidation-warningBorder: #b89500;
  --vscode-inputValidation-errorBackground: #f2dede;
  --vscode-inputValidation-errorBorder: #be1100;
  --vscode-dropdown-background: #ffffff;
  --vscode-dropdown-border: #cecece;
  --vscode-checkbox-background: #ffffff;
  --vscode-checkbox-border: #cecece;
  --vscode-button-foreground: #ffffff;
  --vscode-button-separator: rgba(255, 255, 255, 0.4);
  --vscode-button-background: #007acc;
  --vscode-button-hoverBackground: #0062a3;
  --vscode-button-secondaryForeground: #ffffff;
  --vscode-button-secondaryBackground: #5f6a79;
  --vscode-button-secondaryHoverBackground: #4c5561;
  --vscode-badge-background: #c4c4c4;
  --vscode-badge-foreground: #333333;
  --vscode-scrollbar-shadow: #dddddd;
  --vscode-scrollbarSlider-background: rgba(100, 100, 100, 0.4);
  --vscode-scrollbarSlider-hoverBackground: rgba(100, 100, 100, 0.7);
  --vscode-scrollbarSlider-activeBackground: rgba(0, 0, 0, 0.6);
  --vscode-progressBar-background: #0e70c0;
  --vscode-editorError-foreground: #e51400;
  --vscode-editorWarning-foreground: #bf8803;
  --vscode-editorInfo-foreground: #1a85ff;
  --vscode-editorHint-foreground: #6c6c6c;
  --vscode-sash-hoverBorder: #0090f1;
  --vscode-editor-background: #fafafa;
  --vscode-editor-foreground: #333333;
  --vscode-editorStickyScroll-background: #fafafa;
  --vscode-editorStickyScrollHover-background: #f0f0f0;
  --vscode-editorWidget-background: #f3f3f3;
  --vscode-editorWidget-foreground: #616161;
  --vscode-editorWidget-border: #c8c8c8;
  --vscode-quickInput-background: #f3f3f3;
  --vscode-quickInput-foreground: #616161;
  --vscode-quickInputTitle-background: rgba(0, 0, 0, 0.06);
  --vscode-pickerGroup-foreground: #0066bf;
  --vscode-pickerGroup-border: #cccedb;
  --vscode-keybindingLabel-background: rgba(221, 221, 221, 0.4);
  --vscode-keybindingLabel-foreground: #555555;
  --vscode-keybindingLabel-border: rgba(204, 204, 204, 0.4);
  --vscode-keybindingLabel-bottomBorder: rgba(187, 187, 187, 0.4);
  --vscode-editor-selectionBackground: #ccc9ad;
  --vscode-editor-inactiveSelectionBackground: rgba(204, 201, 173, 0.5);
  --vscode-editor-selectionHighlightBackground: rgba(221, 219, 201, 0.6);
  --vscode-editor-findMatchBackground: #a8ac94;
  --vscode-editor-findMatchHighlightBackground: rgba(234, 92, 0, 0.33);
  --vscode-editor-findRangeHighlightBackground: rgba(180, 180, 180, 0.3);
  --vscode-searchEditor-findMatchBackground: rgba(234, 92, 0, 0.22);
  --vscode-editor-hoverHighlightBackground: rgba(173, 214, 255, 0.15);
  --vscode-editorHoverWidget-background: #f3f3f3;
  --vscode-editorHoverWidget-foreground: #616161;
  --vscode-editorHoverWidget-border: #c8c8c8;
  --vscode-editorHoverWidget-statusBarBackground: #e7e7e7;
  --vscode-editorLink-activeForeground: #0000ff;
  --vscode-editorInlayHint-foreground: rgba(51, 51, 51, 0.8);
  --vscode-editorInlayHint-background: rgba(196, 196, 196, 0.3);
  --vscode-editorInlayHint-typeForeground: rgba(51, 51, 51, 0.8);
  --vscode-editorInlayHint-typeBackground: rgba(196, 196, 196, 0.3);
  --vscode-editorInlayHint-parameterForeground: rgba(51, 51, 51, 0.8);
  --vscode-editorInlayHint-parameterBackground: rgba(196, 196, 196, 0.3);
  --vscode-editorLightBulb-foreground: #ddb100;
  --vscode-editorLightBulbAutoFix-foreground: #007acc;
  --vscode-diffEditor-insertedTextBackground: rgba(156, 204, 44, 0.4);
  --vscode-diffEditor-removedTextBackground: rgba(255, 0, 0, 0.3);
  --vscode-diffEditor-insertedLineBackground: rgba(155, 185, 85, 0.2);
  --vscode-diffEditor-removedLineBackground: rgba(255, 0, 0, 0.2);
  --vscode-diffEditor-diagonalFill: rgba(34, 34, 34, 0.2);
  --vscode-list-focusOutline: #0090f1;
  --vscode-list-activeSelectionBackground: #0060c0;
  --vscode-list-activeSelectionForeground: #ffffff;
  --vscode-list-inactiveSelectionBackground: #e4e6f1;
  --vscode-list-hoverBackground: #f0f0f0;
  --vscode-list-dropBackground: #d6ebff;
  --vscode-list-highlightForeground: #0066bf;
  --vscode-list-focusHighlightForeground: #bbe7ff;
  --vscode-list-invalidItemForeground: #b89500;
  --vscode-list-errorForeground: #b01011;
  --vscode-list-warningForeground: #855f00;
  --vscode-listFilterWidget-background: #f3f3f3;
  --vscode-listFilterWidget-outline: rgba(0, 0, 0, 0);
  --vscode-listFilterWidget-noMatchesOutline: #be1100;
  --vscode-listFilterWidget-shadow: rgba(0, 0, 0, 0.16);
  --vscode-list-filterMatchBackground: rgba(234, 92, 0, 0.33);
  --vscode-tree-indentGuidesStroke: #a9a9a9;
  --vscode-tree-tableColumnsBorder: rgba(97, 97, 97, 0.13);
  --vscode-tree-tableOddRowsBackground: rgba(97, 97, 97, 0.04);
  --vscode-list-deemphasizedForeground: #8e8e90;
  --vscode-quickInputList-focusForeground: #ffffff;
  --vscode-quickInputList-focusBackground: #0060c0;
  --vscode-menu-foreground: #616161;
  --vscode-menu-background: #ffffff;
  --vscode-menu-selectionForeground: #ffffff;
  --vscode-menu-selectionBackground: #0060c0;
  --vscode-menu-separatorBackground: #d4d4d4;
  --vscode-toolbar-hoverBackground: rgba(184, 184, 184, 0.31);
  --vscode-toolbar-activeBackground: rgba(166, 166, 166, 0.31);
  --vscode-editor-snippetTabstopHighlightBackground: rgba(10, 50, 100, 0.2);
  --vscode-editor-snippetFinalTabstopHighlightBorder: rgba(10, 50, 100, 0.5);
  --vscode-breadcrumb-foreground: rgba(97, 97, 97, 0.8);
  --vscode-breadcrumb-background: #fafafa;
  --vscode-breadcrumb-focusForeground: #4e4e4e;
  --vscode-breadcrumb-activeSelectionForeground: #4e4e4e;
  --vscode-breadcrumbPicker-background: #f3f3f3;
  --vscode-merge-currentHeaderBackground: rgba(64, 200, 174, 0.5);
  --vscode-merge-currentContentBackground: rgba(64, 200, 174, 0.2);
  --vscode-merge-incomingHeaderBackground: rgba(64, 166, 255, 0.5);
  --vscode-merge-incomingContentBackground: rgba(64, 166, 255, 0.2);
  --vscode-merge-commonHeaderBackground: rgba(96, 96, 96, 0.4);
  --vscode-merge-commonContentBackground: rgba(96, 96, 96, 0.16);
  --vscode-editorOverviewRuler-currentContentForeground: rgba(64, 200, 174, 0.5);
  --vscode-editorOverviewRuler-incomingContentForeground: rgba(64, 166, 255, 0.5);
  --vscode-editorOverviewRuler-commonContentForeground: rgba(96, 96, 96, 0.4);
  --vscode-editorOverviewRuler-findMatchForeground: rgba(209, 134, 22, 0.49);
  --vscode-editorOverviewRuler-selectionHighlightForeground: rgba(160, 160, 160, 0.8);
  --vscode-minimap-findMatchHighlight: #d18616;
  --vscode-minimap-selectionOccurrenceHighlight: #c9c9c9;
  --vscode-minimap-selectionHighlight: #add6ff;
  --vscode-minimap-errorHighlight: rgba(255, 18, 18, 0.7);
  --vscode-minimap-warningHighlight: #bf8803;
  --vscode-minimap-foregroundOpacity: #000000;
  --vscode-minimapSlider-background: rgba(100, 100, 100, 0.2);
  --vscode-minimapSlider-hoverBackground: rgba(100, 100, 100, 0.35);
  --vscode-minimapSlider-activeBackground: rgba(0, 0, 0, 0.3);
  --vscode-problemsErrorIcon-foreground: #e51400;
  --vscode-problemsWarningIcon-foreground: #bf8803;
  --vscode-problemsInfoIcon-foreground: #1a85ff;
  --vscode-charts-foreground: #616161;
  --vscode-charts-lines: rgba(97, 97, 97, 0.5);
  --vscode-charts-red: #e51400;
  --vscode-charts-blue: #1a85ff;
  --vscode-charts-yellow: #bf8803;
  --vscode-charts-orange: #d18616;
  --vscode-charts-green: #388a34;
  --vscode-charts-purple: #652d90;
  --vscode-editor-lineHighlightBackground: #e6e3c3;
  --vscode-editor-lineHighlightBorder: #eeeeee;
  --vscode-editor-rangeHighlightBackground: rgba(253, 255, 0, 0.2);
  --vscode-editor-symbolHighlightBackground: rgba(234, 92, 0, 0.33);
  --vscode-editorCursor-foreground: #666663;
  --vscode-editorWhitespace-foreground: #bbbbb7;
  --vscode-editorIndentGuide-background: #d3d4d5;
  --vscode-editorIndentGuide-activeBackground: #bbbbb7;
  --vscode-editorLineNumber-foreground: #a2a19c;
  --vscode-editorActiveLineNumber-foreground: #0b216f;
  --vscode-editorLineNumber-activeForeground: #0b216f;
  --vscode-editorRuler-foreground: #d3d3d3;
  --vscode-editorCodeLens-foreground: #919191;
  --vscode-editorBracketMatch-background: rgba(0, 100, 0, 0.1);
  --vscode-editorBracketMatch-border: #b9b9b9;
  --vscode-editorOverviewRuler-border: rgba(127, 127, 127, 0.3);
  --vscode-editorGutter-background: #fafafa;
  --vscode-editorUnnecessaryCode-opacity: rgba(0, 0, 0, 0.47);
  --vscode-editorGhostText-foreground: rgba(0, 0, 0, 0.47);
  --vscode-editorOverviewRuler-rangeHighlightForeground: rgba(0, 122, 204, 0.6);
  --vscode-editorOverviewRuler-errorForeground: rgba(255, 18, 18, 0.7);
  --vscode-editorOverviewRuler-warningForeground: #bf8803;
  --vscode-editorOverviewRuler-infoForeground: #1a85ff;
  --vscode-editorBracketHighlight-foreground1: #0431fa;
  --vscode-editorBracketHighlight-foreground2: #319331;
  --vscode-editorBracketHighlight-foreground3: #7b3814;
  --vscode-editorBracketHighlight-foreground4: rgba(0, 0, 0, 0);
  --vscode-editorBracketHighlight-foreground5: rgba(0, 0, 0, 0);
  --vscode-editorBracketHighlight-foreground6: rgba(0, 0, 0, 0);
  --vscode-editorBracketHighlight-unexpectedBracket\.foreground: rgba(255, 18, 18, 0.8);
  --vscode-editorBracketPairGuide-background1: rgba(0, 0, 0, 0);
  --vscode-editorBracketPairGuide-background2: rgba(0, 0, 0, 0);
  --vscode-editorBracketPairGuide-background3: rgba(0, 0, 0, 0);
  --vscode-editorBracketPairGuide-background4: rgba(0, 0, 0, 0);
  --vscode-editorBracketPairGuide-background5: rgba(0, 0, 0, 0);
  --vscode-editorBracketPairGuide-background6: rgba(0, 0, 0, 0);
  --vscode-editorBracketPairGuide-activeBackground1: rgba(0, 0, 0, 0);
  --vscode-editorBracketPairGuide-activeBackground2: rgba(0, 0, 0, 0);
  --vscode-editorBracketPairGuide-activeBackground3: rgba(0, 0, 0, 0);
  --vscode-editorBracketPairGuide-activeBackground4: rgba(0, 0, 0, 0);
  --vscode-editorBracketPairGuide-activeBackground5: rgba(0, 0, 0, 0);
  --vscode-editorBracketPairGuide-activeBackground6: rgba(0, 0, 0, 0);
  --vscode-editorUnicodeHighlight-border: #cea33d;
  --vscode-editorUnicodeHighlight-background: rgba(206, 163, 61, 0.08);
  --vscode-symbolIcon-arrayForeground: #616161;
  --vscode-symbolIcon-booleanForeground: #616161;
  --vscode-symbolIcon-classForeground: #d67e00;
  --vscode-symbolIcon-colorForeground: #616161;
  --vscode-symbolIcon-constantForeground: #616161;
  --vscode-symbolIcon-constructorForeground: #652d90;
  --vscode-symbolIcon-enumeratorForeground: #d67e00;
  --vscode-symbolIcon-enumeratorMemberForeground: #007acc;
  --vscode-symbolIcon-eventForeground: #d67e00;
  --vscode-symbolIcon-fieldForeground: #007acc;
  --vscode-symbolIcon-fileForeground: #616161;
  --vscode-symbolIcon-folderForeground: #616161;
  --vscode-symbolIcon-functionForeground: #652d90;
  --vscode-symbolIcon-interfaceForeground: #007acc;
  --vscode-symbolIcon-keyForeground: #616161;
  --vscode-symbolIcon-keywordForeground: #616161;
  --vscode-symbolIcon-methodForeground: #652d90;
  --vscode-symbolIcon-moduleForeground: #616161;
  --vscode-symbolIcon-namespaceForeground: #616161;
  --vscode-symbolIcon-nullForeground: #616161;
  --vscode-symbolIcon-numberForeground: #616161;
  --vscode-symbolIcon-objectForeground: #616161;
  --vscode-symbolIcon-operatorForeground: #616161;
  --vscode-symbolIcon-packageForeground: #616161;
  --vscode-symbolIcon-propertyForeground: #616161;
  --vscode-symbolIcon-referenceForeground: #616161;
  --vscode-symbolIcon-snippetForeground: #616161;
  --vscode-symbolIcon-stringForeground: #616161;
  --vscode-symbolIcon-structForeground: #616161;
  --vscode-symbolIcon-textForeground: #616161;
  --vscode-symbolIcon-typeParameterForeground: #616161;
  --vscode-symbolIcon-unitForeground: #616161;
  --vscode-symbolIcon-variableForeground: #007acc;
  --vscode-editorHoverWidget-highlightForeground: #0066bf;
  --vscode-editorOverviewRuler-bracketMatchForeground: #a0a0a0;
  --vscode-editor-foldBackground: rgba(204, 201, 173, 0.3);
  --vscode-editorGutter-foldingControlForeground: #424242;
  --vscode-editor-linkedEditingBackground: rgba(255, 0, 0, 0.3);
  --vscode-editor-wordHighlightBackground: rgba(87, 87, 87, 0.25);
  --vscode-editor-wordHighlightStrongBackground: rgba(14, 99, 156, 0.25);
  --vscode-editorOverviewRuler-wordHighlightForeground: rgba(160, 160, 160, 0.8);
  --vscode-editorOverviewRuler-wordHighlightStrongForeground: rgba(192, 160, 192, 0.8);
  --vscode-peekViewTitle-background: rgba(26, 133, 255, 0.1);
  --vscode-peekViewTitleLabel-foreground: #000000;
  --vscode-peekViewTitleDescription-foreground: #616161;
  --vscode-peekView-border: #1a85ff;
  --vscode-peekViewResult-background: #f3f3f3;
  --vscode-peekViewResult-lineForeground: #646465;
  --vscode-peekViewResult-fileForeground: #1e1e1e;
  --vscode-peekViewResult-selectionBackground: rgba(51, 153, 255, 0.2);
  --vscode-peekViewResult-selectionForeground: #6c6c6c;
  --vscode-peekViewEditor-background: #f2f8fc;
  --vscode-peekViewEditorGutter-background: #f2f8fc;
  --vscode-peekViewResult-matchHighlightBackground: rgba(234, 92, 0, 0.3);
  --vscode-peekViewEditor-matchHighlightBackground: rgba(245, 216, 2, 0.87);
  --vscode-editorMarkerNavigationError-background: #e51400;
  --vscode-editorMarkerNavigationError-headerBackground: rgba(229, 20, 0, 0.1);
  --vscode-editorMarkerNavigationWarning-background: #bf8803;
  --vscode-editorMarkerNavigationWarning-headerBackground: rgba(191, 136, 3, 0.1);
  --vscode-editorMarkerNavigationInfo-background: #1a85ff;
  --vscode-editorMarkerNavigationInfo-headerBackground: rgba(26, 133, 255, 0.1);
  --vscode-editorMarkerNavigation-background: #fafafa;
  --vscode-editorSuggestWidget-background: #f3f3f3;
  --vscode-editorSuggestWidget-border: #c8c8c8;
  --vscode-editorSuggestWidget-foreground: #333333;
  --vscode-editorSuggestWidget-selectedForeground: #ffffff;
  --vscode-editorSuggestWidget-selectedBackground: #0060c0;
  --vscode-editorSuggestWidget-highlightForeground: #0066bf;
  --vscode-editorSuggestWidget-focusHighlightForeground: #bbe7ff;
  --vscode-editorSuggestWidgetStatus-foreground: rgba(51, 51, 51, 0.5);
  --vscode-tab-activeBackground: #fafafa;
  --vscode-tab-unfocusedActiveBackground: #fafafa;
  --vscode-tab-inactiveBackground: #ececec;
  --vscode-tab-unfocusedInactiveBackground: #ececec;
  --vscode-tab-activeForeground: #333333;
  --vscode-tab-inactiveForeground: rgba(51, 51, 51, 0.7);
  --vscode-tab-unfocusedActiveForeground: rgba(51, 51, 51, 0.7);
  --vscode-tab-unfocusedInactiveForeground: rgba(51, 51, 51, 0.35);
  --vscode-tab-border: #f3f3f3;
  --vscode-tab-lastPinnedBorder: #a9a9a9;
  --vscode-tab-activeModifiedBorder: #33aaee;
  --vscode-tab-inactiveModifiedBorder: rgba(51, 170, 238, 0.5);
  --vscode-tab-unfocusedActiveModifiedBorder: rgba(51, 170, 238, 0.7);
  --vscode-tab-unfocusedInactiveModifiedBorder: rgba(51, 170, 238, 0.25);
  --vscode-editorPane-background: #fafafa;
  --vscode-editorGroupHeader-tabsBackground: #f3f3f3;
  --vscode-editorGroupHeader-noTabsBackground: #fafafa;
  --vscode-editorGroup-border: #e7e7e7;
  --vscode-editorGroup-dropBackground: rgba(38, 119, 203, 0.18);
  --vscode-editorGroup-dropIntoPromptForeground: #616161;
  --vscode-editorGroup-dropIntoPromptBackground: #f3f3f3;
  --vscode-sideBySideEditor-horizontalBorder: #e7e7e7;
  --vscode-sideBySideEditor-verticalBorder: #e7e7e7;
  --vscode-panel-background: #fafafa;
  --vscode-panel-border: rgba(128, 128, 128, 0.35);
  --vscode-panelTitle-activeForeground: #424242;
  --vscode-panelTitle-inactiveForeground: rgba(66, 66, 66, 0.75);
  --vscode-panelTitle-activeBorder: #424242;
  --vscode-panelInput-border: #dddddd;
  --vscode-panel-dropBorder: #424242;
  --vscode-panelSection-dropBackground: rgba(38, 119, 203, 0.18);
  --vscode-panelSectionHeader-background: rgba(128, 128, 128, 0.2);
  --vscode-panelSection-border: rgba(128, 128, 128, 0.35);
  --vscode-banner-background: #004386;
  --vscode-banner-foreground: #ffffff;
  --vscode-banner-iconForeground: #1a85ff;
  --vscode-statusBar-foreground: #ffffff;
  --vscode-statusBar-noFolderForeground: #ffffff;
  --vscode-statusBar-background: #007acc;
  --vscode-statusBar-noFolderBackground: #68217a;
  --vscode-statusBar-focusBorder: #ffffff;
  --vscode-statusBarItem-activeBackground: rgba(255, 255, 255, 0.18);
  --vscode-statusBarItem-focusBorder: #ffffff;
  --vscode-statusBarItem-hoverBackground: rgba(255, 255, 255, 0.12);
  --vscode-statusBarItem-compactHoverBackground: rgba(255, 255, 255, 0.2);
  --vscode-statusBarItem-prominentForeground: #ffffff;
  --vscode-statusBarItem-prominentBackground: rgba(0, 0, 0, 0.5);
  --vscode-statusBarItem-prominentHoverBackground: rgba(0, 0, 0, 0.3);
  --vscode-statusBarItem-errorBackground: #611708;
  --vscode-statusBarItem-errorForeground: #ffffff;
  --vscode-statusBarItem-warningBackground: #725102;
  --vscode-statusBarItem-warningForeground: #ffffff;
  --vscode-activityBar-background: #2c2c2c;
  --vscode-activityBar-foreground: #ffffff;
  --vscode-activityBar-inactiveForeground: rgba(255, 255, 255, 0.4);
  --vscode-activityBar-activeBorder: #ffffff;
  --vscode-activityBar-dropBorder: #ffffff;
  --vscode-activityBarBadge-background: #007acc;
  --vscode-activityBarBadge-foreground: #ffffff;
  --vscode-statusBarItem-remoteBackground: #007acc;
  --vscode-statusBarItem-remoteForeground: #ffffff;
  --vscode-extensionBadge-remoteBackground: #007acc;
  --vscode-extensionBadge-remoteForeground: #ffffff;
  --vscode-sideBar-background: #f3f3f3;
  --vscode-sideBar-dropBackground: rgba(38, 119, 203, 0.18);
  --vscode-sideBarSectionHeader-background: rgba(128, 128, 128, 0.2);
  --vscode-titleBar-activeForeground: #333333;
  --vscode-titleBar-inactiveForeground: rgba(51, 51, 51, 0.6);
  --vscode-titleBar-activeBackground: #dddddd;
  --vscode-titleBar-inactiveBackground: rgba(221, 221, 221, 0.6);
  --vscode-menubar-selectionForeground: #333333;
  --vscode-menubar-selectionBackground: rgba(184, 184, 184, 0.31);
  --vscode-notifications-foreground: #616161;
  --vscode-notifications-background: #f3f3f3;
  --vscode-notificationLink-foreground: #006ab1;
  --vscode-notificationCenterHeader-background: #e7e7e7;
  --vscode-notifications-border: #e7e7e7;
  --vscode-notificationsErrorIcon-foreground: #e51400;
  --vscode-notificationsWarningIcon-foreground: #bf8803;
  --vscode-notificationsInfoIcon-foreground: #1a85ff;
  --vscode-commandCenter-foreground: #333333;
  --vscode-commandCenter-activeForeground: #333333;
  --vscode-commandCenter-activeBackground: rgba(184, 184, 184, 0.31);
  --vscode-commandCenter-border: rgba(128, 128, 128, 0.35);
  --vscode-editorCommentsWidget-resolvedBorder: rgba(97, 97, 97, 0.5);
  --vscode-editorCommentsWidget-unresolvedBorder: #1a85ff;
  --vscode-editorCommentsWidget-rangeBackground: rgba(26, 133, 255, 0.1);
  --vscode-editorCommentsWidget-rangeBorder: rgba(26, 133, 255, 0.4);
  --vscode-editorCommentsWidget-rangeActiveBackground: rgba(26, 133, 255, 0.1);
  --vscode-editorCommentsWidget-rangeActiveBorder: rgba(26, 133, 255, 0.4);
  --vscode-editorGutter-commentRangeForeground: #d5d8e9;
  --vscode-debugToolBar-background: #f3f3f3;
  --vscode-debugIcon-startForeground: #388a34;
  --vscode-editor-stackFrameHighlightBackground: rgba(255, 255, 102, 0.45);
  --vscode-editor-focusedStackFrameHighlightBackground: rgba(206, 231, 206, 0.45);
  --vscode-mergeEditor-change\.background: rgba(155, 185, 85, 0.2);
  --vscode-mergeEditor-change\.word\.background: rgba(156, 204, 44, 0.4);
  --vscode-mergeEditor-conflict\.unhandledUnfocused\.border: rgba(255, 166, 0, 0.48);
  --vscode-mergeEditor-conflict\.unhandledFocused\.border: #ffa600;
  --vscode-mergeEditor-conflict\.handledUnfocused\.border: rgba(134, 134, 134, 0.29);
  --vscode-mergeEditor-conflict\.handledFocused\.border: rgba(193, 193, 193, 0.8);
  --vscode-mergeEditor-conflict\.handled\.minimapOverViewRuler: rgba(173, 172, 168, 0.93);
  --vscode-mergeEditor-conflict\.unhandled\.minimapOverViewRuler: #fcba03;
  --vscode-mergeEditor-conflictingLines\.background: rgba(255, 234, 0, 0.28);
  --vscode-settings-headerForeground: #444444;
  --vscode-settings-modifiedItemIndicator: #66afe0;
  --vscode-settings-headerBorder: rgba(128, 128, 128, 0.35);
  --vscode-settings-sashBorder: rgba(128, 128, 128, 0.35);
  --vscode-settings-dropdownBackground: #ffffff;
  --vscode-settings-dropdownBorder: #cecece;
  --vscode-settings-dropdownListBorder: #c8c8c8;
  --vscode-settings-checkboxBackground: #ffffff;
  --vscode-settings-checkboxBorder: #cecece;
  --vscode-settings-textInputBackground: #ffffff;
  --vscode-settings-textInputForeground: #616161;
  --vscode-settings-numberInputBackground: #ffffff;
  --vscode-settings-numberInputForeground: #616161;
  --vscode-settings-focusedRowBackground: rgba(240, 240, 240, 0.6);
  --vscode-settings-rowHoverBackground: rgba(240, 240, 240, 0.3);
  --vscode-settings-focusedRowBorder: rgba(0, 0, 0, 0.12);
  --vscode-terminal-foreground: #333333;
  --vscode-terminal-selectionBackground: #ccc9ad;
  --vscode-terminal-inactiveSelectionBackground: rgba(204, 201, 173, 0.5);
  --vscode-terminalCommandDecoration-defaultBackground: rgba(0, 0, 0, 0.25);
  --vscode-terminalCommandDecoration-successBackground: #2090d3;
  --vscode-terminalCommandDecoration-errorBackground: #e51400;
  --vscode-terminalOverviewRuler-cursorForeground: rgba(160, 160, 160, 0.8);
  --vscode-terminal-border: rgba(128, 128, 128, 0.35);
  --vscode-terminal-findMatchBackground: #a8ac94;
  --vscode-terminal-findMatchHighlightBackground: rgba(234, 92, 0, 0.33);
  --vscode-terminalOverviewRuler-findMatchForeground: rgba(209, 134, 22, 0.49);
  --vscode-terminal-dropBackground: rgba(38, 119, 203, 0.18);
  --vscode-testing-iconFailed: #f14c4c;
  --vscode-testing-iconErrored: #f14c4c;
  --vscode-testing-iconPassed: #73c991;
  --vscode-testing-runAction: #73c991;
  --vscode-testing-iconQueued: #cca700;
  --vscode-testing-iconUnset: #848484;
  --vscode-testing-iconSkipped: #848484;
  --vscode-testing-peekBorder: #e51400;
  --vscode-testing-peekHeaderBackground: rgba(229, 20, 0, 0.1);
  --vscode-testing-message\.error\.decorationForeground: #e51400;
  --vscode-testing-message\.error\.lineBackground: rgba(255, 0, 0, 0.2);
  --vscode-testing-message\.info\.decorationForeground: rgba(51, 51, 51, 0.5);
  --vscode-welcomePage-tileBackground: #f3f3f3;
  --vscode-welcomePage-tileHoverBackground: #dbdbdb;
  --vscode-welcomePage-tileShadow: rgba(0, 0, 0, 0.16);
  --vscode-welcomePage-progress\.background: #ffffff;
  --vscode-welcomePage-progress\.foreground: #006ab1;
  --vscode-debugExceptionWidget-border: #a31515;
  --vscode-debugExceptionWidget-background: #f1dfde;
  --vscode-ports-iconRunningProcessForeground: #007acc;
  --vscode-statusBar-debuggingBackground: #cc6633;
  --vscode-statusBar-debuggingForeground: #ffffff;
  --vscode-editor-inlineValuesForeground: rgba(0, 0, 0, 0.5);
  --vscode-editor-inlineValuesBackground: rgba(255, 200, 0, 0.2);
  --vscode-editorGutter-modifiedBackground: #2090d3;
  --vscode-editorGutter-addedBackground: #48985d;
  --vscode-editorGutter-deletedBackground: #e51400;
  --vscode-minimapGutter-modifiedBackground: #2090d3;
  --vscode-minimapGutter-addedBackground: #48985d;
  --vscode-minimapGutter-deletedBackground: #e51400;
  --vscode-editorOverviewRuler-modifiedForeground: rgba(32, 144, 211, 0.6);
  --vscode-editorOverviewRuler-addedForeground: rgba(72, 152, 93, 0.6);
  --vscode-editorOverviewRuler-deletedForeground: rgba(229, 20, 0, 0.6);
  --vscode-debugIcon-breakpointForeground: #e51400;
  --vscode-debugIcon-breakpointDisabledForeground: #848484;
  --vscode-debugIcon-breakpointUnverifiedForeground: #848484;
  --vscode-debugIcon-breakpointCurrentStackframeForeground: #be8700;
  --vscode-debugIcon-breakpointStackframeForeground: #89d185;
  --vscode-notebook-cellBorderColor: #e4e6f1;
  --vscode-notebook-focusedEditorBorder: #0090f1;
  --vscode-notebookStatusSuccessIcon-foreground: #388a34;
  --vscode-notebookStatusErrorIcon-foreground: #a1260d;
  --vscode-notebookStatusRunningIcon-foreground: #616161;
  --vscode-notebook-cellToolbarSeparator: rgba(128, 128, 128, 0.35);
  --vscode-notebook-selectedCellBackground: #e4e6f1;
  --vscode-notebook-selectedCellBorder: #e4e6f1;
  --vscode-notebook-focusedCellBorder: #0090f1;
  --vscode-notebook-inactiveFocusedCellBorder: #e4e6f1;
  --vscode-notebook-cellStatusBarItemHoverBackground: rgba(0, 0, 0, 0.08);
  --vscode-notebook-cellInsertionIndicator: #0090f1;
  --vscode-notebookScrollbarSlider-background: rgba(100, 100, 100, 0.4);
  --vscode-notebookScrollbarSlider-hoverBackground: rgba(100, 100, 100, 0.7);
  --vscode-notebookScrollbarSlider-activeBackground: rgba(0, 0, 0, 0.6);
  --vscode-notebook-symbolHighlightBackground: rgba(253, 255, 0, 0.2);
  --vscode-notebook-cellEditorBackground: #f3f3f3;
  --vscode-notebook-editorBackground: #fafafa;
  --vscode-keybindingTable-headerBackground: rgba(97, 97, 97, 0.04);
  --vscode-keybindingTable-rowsBackground: rgba(97, 97, 97, 0.04);
  --vscode-scm-providerBorder: #c8c8c8;
  --vscode-debugTokenExpression-name: #9b46b0;
  --vscode-debugTokenExpression-value: rgba(108, 108, 108, 0.8);
  --vscode-debugTokenExpression-string: #a31515;
  --vscode-debugTokenExpression-boolean: #0000ff;
  --vscode-debugTokenExpression-number: #098658;
  --vscode-debugTokenExpression-error: #e51400;
  --vscode-debugView-exceptionLabelForeground: #ffffff;
  --vscode-debugView-exceptionLabelBackground: #a31515;
  --vscode-debugView-stateLabelForeground: #616161;
  --vscode-debugView-stateLabelBackground: rgba(136, 136, 136, 0.27);
  --vscode-debugView-valueChangedHighlight: #569cd6;
  --vscode-debugConsole-infoForeground: #1a85ff;
  --vscode-debugConsole-warningForeground: #bf8803;
  --vscode-debugConsole-errorForeground: #a1260d;
  --vscode-debugConsole-sourceForeground: #616161;
  --vscode-debugConsoleInputIcon-foreground: #616161;
  --vscode-debugIcon-pauseForeground: #007acc;
  --vscode-debugIcon-stopForeground: #a1260d;
  --vscode-debugIcon-disconnectForeground: #a1260d;
  --vscode-debugIcon-restartForeground: #388a34;
  --vscode-debugIcon-stepOverForeground: #007acc;
  --vscode-debugIcon-stepIntoForeground: #007acc;
  --vscode-debugIcon-stepOutForeground: #007acc;
  --vscode-debugIcon-continueForeground: #007acc;
  --vscode-debugIcon-stepBackForeground: #007acc;
  --vscode-extensionButton-prominentBackground: #007acc;
  --vscode-extensionButton-prominentForeground: #ffffff;
  --vscode-extensionButton-prominentHoverBackground: #0062a3;
  --vscode-extensionIcon-starForeground: #df6100;
  --vscode-extensionIcon-verifiedForeground: #006ab1;
  --vscode-extensionIcon-preReleaseForeground: #1d9271;
  --vscode-extensionIcon-sponsorForeground: #b51e78;
  --vscode-terminal-ansiBlack: #000000;
  --vscode-terminal-ansiRed: #cd3131;
  --vscode-terminal-ansiGreen: #00bc00;
  --vscode-terminal-ansiYellow: #949800;
  --vscode-terminal-ansiBlue: #0451a5;
  --vscode-terminal-ansiMagenta: #bc05bc;
  --vscode-terminal-ansiCyan: #0598bc;
  --vscode-terminal-ansiWhite: #555555;
  --vscode-terminal-ansiBrightBlack: #666666;
  --vscode-terminal-ansiBrightRed: #cd3131;
  --vscode-terminal-ansiBrightGreen: #14ce14;
  --vscode-terminal-ansiBrightYellow: #b5ba00;
  --vscode-terminal-ansiBrightBlue: #0451a5;
  --vscode-terminal-ansiBrightMagenta: #bc05bc;
  --vscode-terminal-ansiBrightCyan: #0598bc;
  --vscode-terminal-ansiBrightWhite: #a5a5a5;
  --vscode-interactive-activeCodeBorder: #1a85ff;
  --vscode-interactive-inactiveCodeBorder: #e4e6f1;
  --vscode-gitDecoration-addedResourceForeground: #587c0c;
  --vscode-gitDecoration-modifiedResourceForeground: #895503;
  --vscode-gitDecoration-deletedResourceForeground: #ad0707;
  --vscode-gitDecoration-renamedResourceForeground: #007100;
  --vscode-gitDecoration-untrackedResourceForeground: #007100;
  --vscode-gitDecoration-ignoredResourceForeground: #8e8e90;
  --vscode-gitDecoration-stageModifiedResourceForeground: #895503;
  --vscode-gitDecoration-stageDeletedResourceForeground: #ad0707;
  --vscode-gitDecoration-conflictingResourceForeground: #ad0707;
  --vscode-gitDecoration-submoduleResourceForeground: #1258a7;
}

.dark {
  --vscode-font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  --vscode-font-weight: normal;
  --vscode-font-size: 13px;
  --vscode-editor-font-family: Menlo, Monaco, "Courier New", monospace;
  --vscode-editor-font-weight: normal;
  --vscode-editor-font-size: 12px;
  --vscode-foreground: #adbac7;
  --vscode-disabledForeground: rgba(204, 204, 204, 0.5);
  --vscode-errorForeground: #e5534b;
  --vscode-descriptionForeground: #768390;
  --vscode-icon-foreground: #768390;
  --vscode-focusBorder: #316dca;
  --vscode-textSeparator-foreground: #373e47;
  --vscode-textLink-foreground: #539bf5;
  --vscode-textLink-activeForeground: #539bf5;
  --vscode-textPreformat-foreground: #768390;
  --vscode-textBlockQuote-background: #1c2128;
  --vscode-textBlockQuote-border: #444c56;
  --vscode-textCodeBlock-background: rgba(99, 110, 123, 0.4);
  --vscode-widget-shadow: rgba(0, 0, 0, 0.36);
  --vscode-input-background: #22272e;
  --vscode-input-foreground: #adbac7;
  --vscode-input-border: #444c56;
  --vscode-inputOption-activeBorder: rgba(0, 122, 204, 0);
  --vscode-inputOption-hoverBackground: rgba(90, 93, 94, 0.5);
  --vscode-inputOption-activeBackground: rgba(49, 109, 202, 0.4);
  --vscode-inputOption-activeForeground: #ffffff;
  --vscode-input-placeholderForeground: #545d68;
  --vscode-inputValidation-infoBackground: #063b49;
  --vscode-inputValidation-infoBorder: #007acc;
  --vscode-inputValidation-warningBackground: #352a05;
  --vscode-inputValidation-warningBorder: #b89500;
  --vscode-inputValidation-errorBackground: #5a1d1d;
  --vscode-inputValidation-errorBorder: #be1100;
  --vscode-dropdown-background: #2d333b;
  --vscode-dropdown-listBackground: #2d333b;
  --vscode-dropdown-foreground: #adbac7;
  --vscode-dropdown-border: #444c56;
  --vscode-checkbox-background: #2d333b;
  --vscode-checkbox-foreground: #adbac7;
  --vscode-checkbox-border: #444c56;
  --vscode-button-foreground: #ffffff;
  --vscode-button-separator: rgba(255, 255, 255, 0.4);
  --vscode-button-background: #347d39;
  --vscode-button-hoverBackground: #46954a;
  --vscode-button-secondaryForeground: #adbac7;
  --vscode-button-secondaryBackground: #3d444d;
  --vscode-button-secondaryHoverBackground: #444c56;
  --vscode-badge-background: #316dca;
  --vscode-badge-foreground: #cdd9e5;
  --vscode-scrollbar-shadow: rgba(84, 93, 104, 0.2);
  --vscode-scrollbarSlider-background: rgba(99, 110, 123, 0.2);
  --vscode-scrollbarSlider-hoverBackground: rgba(99, 110, 123, 0.27);
  --vscode-scrollbarSlider-activeBackground: rgba(99, 110, 123, 0.53);
  --vscode-progressBar-background: #316dca;
  --vscode-editorError-foreground: #f14c4c;
  --vscode-editorWarning-foreground: #cca700;
  --vscode-editorInfo-foreground: #3794ff;
  --vscode-editorHint-foreground: rgba(238, 238, 238, 0.7);
  --vscode-sash-hoverBorder: #316dca;
  --vscode-editor-background: #22272e;
  --vscode-editor-foreground: #adbac7;
  --vscode-editorStickyScroll-background: #22272e;
  --vscode-editorStickyScrollHover-background: #2a2d2e;
  --vscode-editorWidget-background: #2d333b;
  --vscode-editorWidget-foreground: #adbac7;
  --vscode-editorWidget-border: #454545;
  --vscode-quickInput-background: #2d333b;
  --vscode-quickInput-foreground: #adbac7;
  --vscode-quickInputTitle-background: rgba(255, 255, 255, 0.1);
  --vscode-pickerGroup-foreground: #768390;
  --vscode-pickerGroup-border: #444c56;
  --vscode-keybindingLabel-background: rgba(128, 128, 128, 0.17);
  --vscode-keybindingLabel-foreground: #adbac7;
  --vscode-keybindingLabel-border: rgba(51, 51, 51, 0.6);
  --vscode-keybindingLabel-bottomBorder: rgba(68, 68, 68, 0.6);
  --vscode-editor-selectionBackground: #264f78;
  --vscode-editor-inactiveSelectionBackground: rgba(38, 79, 120, 0.5);
  --vscode-editor-selectionHighlightBackground: rgba(87, 171, 90, 0.25);
  --vscode-editor-findMatchBackground: #966600;
  --vscode-editor-findMatchHighlightBackground: rgba(234, 197, 95, 0.5);
  --vscode-editor-findRangeHighlightBackground: rgba(58, 61, 65, 0.4);
  --vscode-searchEditor-findMatchBackground: rgba(234, 197, 95, 0.33);
  --vscode-editor-hoverHighlightBackground: rgba(38, 79, 120, 0.25);
  --vscode-editorHoverWidget-background: #2d333b;
  --vscode-editorHoverWidget-foreground: #adbac7;
  --vscode-editorHoverWidget-border: #454545;
  --vscode-editorHoverWidget-statusBarBackground: #363d47;
  --vscode-editorLink-activeForeground: #4e94ce;
  --vscode-editorInlayHint-foreground: #768390;
  --vscode-editorInlayHint-background: rgba(118, 131, 144, 0.2);
  --vscode-editorInlayHint-typeForeground: #768390;
  --vscode-editorInlayHint-typeBackground: rgba(118, 131, 144, 0.2);
  --vscode-editorInlayHint-parameterForeground: #768390;
  --vscode-editorInlayHint-parameterBackground: rgba(118, 131, 144, 0.2);
  --vscode-editorLightBulb-foreground: #ffcc00;
  --vscode-editorLightBulbAutoFix-foreground: #75beff;
  --vscode-diffEditor-insertedTextBackground: rgba(52, 125, 57, 0.3);
  --vscode-diffEditor-removedTextBackground: rgba(201, 60, 55, 0.3);
  --vscode-diffEditor-insertedLineBackground: rgba(52, 125, 57, 0.2);
  --vscode-diffEditor-removedLineBackground: rgba(201, 60, 55, 0.2);
  --vscode-diffEditor-diagonalFill: rgba(204, 204, 204, 0.2);
  --vscode-list-focusBackground: rgba(65, 132, 228, 0.15);
  --vscode-list-focusForeground: #adbac7;
  --vscode-list-focusOutline: #316dca;
  --vscode-list-activeSelectionBackground: rgba(99, 110, 123, 0.4);
  --vscode-list-activeSelectionForeground: #adbac7;
  --vscode-list-inactiveSelectionBackground: rgba(99, 110, 123, 0.4);
  --vscode-list-inactiveSelectionForeground: #adbac7;
  --vscode-list-inactiveFocusBackground: rgba(65, 132, 228, 0.15);
  --vscode-list-hoverBackground: rgba(99, 110, 123, 0.1);
  --vscode-list-hoverForeground: #adbac7;
  --vscode-list-dropBackground: #062f4a;
  --vscode-list-highlightForeground: #539bf5;
  --vscode-list-focusHighlightForeground: #539bf5;
  --vscode-list-invalidItemForeground: #b89500;
  --vscode-list-errorForeground: #f88070;
  --vscode-list-warningForeground: #cca700;
  --vscode-listFilterWidget-background: #2d333b;
  --vscode-listFilterWidget-outline: rgba(0, 0, 0, 0);
  --vscode-listFilterWidget-noMatchesOutline: #be1100;
  --vscode-listFilterWidget-shadow: rgba(0, 0, 0, 0.36);
  --vscode-list-filterMatchBackground: rgba(234, 197, 95, 0.5);
  --vscode-tree-indentGuidesStroke: #373e47;
  --vscode-tree-tableColumnsBorder: rgba(204, 204, 204, 0.13);
  --vscode-tree-tableOddRowsBackground: rgba(173, 186, 199, 0.04);
  --vscode-list-deemphasizedForeground: #8c8c8c;
  --vscode-quickInputList-focusForeground: #adbac7;
  --vscode-quickInputList-focusBackground: rgba(99, 110, 123, 0.4);
  --vscode-menu-foreground: #adbac7;
  --vscode-menu-background: #2d333b;
  --vscode-menu-selectionForeground: #adbac7;
  --vscode-menu-selectionBackground: rgba(99, 110, 123, 0.4);
  --vscode-menu-separatorBackground: #606060;
  --vscode-toolbar-hoverBackground: rgba(90, 93, 94, 0.31);
  --vscode-toolbar-activeBackground: rgba(99, 102, 103, 0.31);
  --vscode-editor-snippetTabstopHighlightBackground: rgba(124, 124, 124, 0.3);
  --vscode-editor-snippetFinalTabstopHighlightBorder: #525252;
  --vscode-breadcrumb-foreground: #768390;
  --vscode-breadcrumb-background: #22272e;
  --vscode-breadcrumb-focusForeground: #adbac7;
  --vscode-breadcrumb-activeSelectionForeground: #768390;
  --vscode-breadcrumbPicker-background: #2d333b;
  --vscode-merge-currentHeaderBackground: rgba(64, 200, 174, 0.5);
  --vscode-merge-currentContentBackground: rgba(64, 200, 174, 0.2);
  --vscode-merge-incomingHeaderBackground: rgba(64, 166, 255, 0.5);
  --vscode-merge-incomingContentBackground: rgba(64, 166, 255, 0.2);
  --vscode-merge-commonHeaderBackground: rgba(96, 96, 96, 0.4);
  --vscode-merge-commonContentBackground: rgba(96, 96, 96, 0.16);
  --vscode-editorOverviewRuler-currentContentForeground: rgba(64, 200, 174, 0.5);
  --vscode-editorOverviewRuler-incomingContentForeground: rgba(64, 166, 255, 0.5);
  --vscode-editorOverviewRuler-commonContentForeground: rgba(96, 96, 96, 0.4);
  --vscode-editorOverviewRuler-findMatchForeground: rgba(209, 134, 22, 0.49);
  --vscode-editorOverviewRuler-selectionHighlightForeground: rgba(160, 160, 160, 0.8);
  --vscode-minimap-findMatchHighlight: #d18616;
  --vscode-minimap-selectionOccurrenceHighlight: #676767;
  --vscode-minimap-selectionHighlight: #264f78;
  --vscode-minimap-errorHighlight: rgba(255, 18, 18, 0.7);
  --vscode-minimap-warningHighlight: #cca700;
  --vscode-minimap-foregroundOpacity: #000000;
  --vscode-minimapSlider-background: rgba(99, 110, 123, 0.1);
  --vscode-minimapSlider-hoverBackground: rgba(99, 110, 123, 0.14);
  --vscode-minimapSlider-activeBackground: rgba(99, 110, 123, 0.27);
  --vscode-problemsErrorIcon-foreground: #f14c4c;
  --vscode-problemsWarningIcon-foreground: #cca700;
  --vscode-problemsInfoIcon-foreground: #3794ff;
  --vscode-charts-foreground: #adbac7;
  --vscode-charts-lines: rgba(173, 186, 199, 0.5);
  --vscode-charts-red: #f14c4c;
  --vscode-charts-blue: #3794ff;
  --vscode-charts-yellow: #cca700;
  --vscode-charts-orange: #d18616;
  --vscode-charts-green: #89d185;
  --vscode-charts-purple: #b180d7;
  --vscode-editor-lineHighlightBackground: rgba(99, 110, 123, 0.1);
  --vscode-editor-lineHighlightBorder: #282828;
  --vscode-editor-rangeHighlightBackground: rgba(255, 255, 255, 0.04);
  --vscode-editor-symbolHighlightBackground: rgba(234, 197, 95, 0.5);
  --vscode-editorCursor-foreground: #539bf5;
  --vscode-editorWhitespace-foreground: #545d68;
  --vscode-editorIndentGuide-background: rgba(173, 186, 199, 0.12);
  --vscode-editorIndentGuide-activeBackground: rgba(173, 186, 199, 0.24);
  --vscode-editorLineNumber-foreground: #636e7b;
  --vscode-editorActiveLineNumber-foreground: #c6c6c6;
  --vscode-editorLineNumber-activeForeground: #adbac7;
  --vscode-editorRuler-foreground: #5a5a5a;
  --vscode-editorCodeLens-foreground: #999999;
  --vscode-editorBracketMatch-background: rgba(87, 171, 90, 0.25);
  --vscode-editorBracketMatch-border: rgba(87, 171, 90, 0.6);
  --vscode-editorOverviewRuler-border: #1c2128;
  --vscode-editorGutter-background: #22272e;
  --vscode-editorUnnecessaryCode-opacity: rgba(0, 0, 0, 0.67);
  --vscode-editorGhostText-foreground: rgba(255, 255, 255, 0.34);
  --vscode-editorOverviewRuler-rangeHighlightForeground: rgba(0, 122, 204, 0.6);
  --vscode-editorOverviewRuler-errorForeground: rgba(255, 18, 18, 0.7);
  --vscode-editorOverviewRuler-warningForeground: #cca700;
  --vscode-editorOverviewRuler-infoForeground: #3794ff;
  --vscode-editorBracketHighlight-foreground1: #6cb6ff;
  --vscode-editorBracketHighlight-foreground2: #6bc46d;
  --vscode-editorBracketHighlight-foreground3: #daaa3f;
  --vscode-editorBracketHighlight-foreground4: #ff938a;
  --vscode-editorBracketHighlight-foreground5: #fc8dc7;
  --vscode-editorBracketHighlight-foreground6: #dcbdfb;
  --vscode-editorBracketHighlight-unexpectedBracket\.foreground: #768390;
  --vscode-editorBracketPairGuide-background1: rgba(0, 0, 0, 0);
  --vscode-editorBracketPairGuide-background2: rgba(0, 0, 0, 0);
  --vscode-editorBracketPairGuide-background3: rgba(0, 0, 0, 0);
  --vscode-editorBracketPairGuide-background4: rgba(0, 0, 0, 0);
  --vscode-editorBracketPairGuide-background5: rgba(0, 0, 0, 0);
  --vscode-editorBracketPairGuide-background6: rgba(0, 0, 0, 0);
  --vscode-editorBracketPairGuide-activeBackground1: rgba(0, 0, 0, 0);
  --vscode-editorBracketPairGuide-activeBackground2: rgba(0, 0, 0, 0);
  --vscode-editorBracketPairGuide-activeBackground3: rgba(0, 0, 0, 0);
  --vscode-editorBracketPairGuide-activeBackground4: rgba(0, 0, 0, 0);
  --vscode-editorBracketPairGuide-activeBackground5: rgba(0, 0, 0, 0);
  --vscode-editorBracketPairGuide-activeBackground6: rgba(0, 0, 0, 0);
  --vscode-editorUnicodeHighlight-border: #bd9b03;
  --vscode-editorUnicodeHighlight-background: rgba(189, 155, 3, 0.15);
  --vscode-symbolIcon-arrayForeground: #e0823d;
  --vscode-symbolIcon-booleanForeground: #539bf5;
  --vscode-symbolIcon-classForeground: #e0823d;
  --vscode-symbolIcon-colorForeground: #6cb6ff;
  --vscode-symbolIcon-constantForeground: #adbac7;
  --vscode-symbolIcon-constructorForeground: #dcbdfb;
  --vscode-symbolIcon-enumeratorForeground: #e0823d;
  --vscode-symbolIcon-enumeratorMemberForeground: #539bf5;
  --vscode-symbolIcon-eventForeground: #636e7b;
  --vscode-symbolIcon-fieldForeground: #e0823d;
  --vscode-symbolIcon-fileForeground: #c69026;
  --vscode-symbolIcon-folderForeground: #c69026;
  --vscode-symbolIcon-functionForeground: #b083f0;
  --vscode-symbolIcon-interfaceForeground: #e0823d;
  --vscode-symbolIcon-keyForeground: #539bf5;
  --vscode-symbolIcon-keywordForeground: #f47067;
  --vscode-symbolIcon-methodForeground: #b083f0;
  --vscode-symbolIcon-moduleForeground: #f47067;
  --vscode-symbolIcon-namespaceForeground: #f47067;
  --vscode-symbolIcon-nullForeground: #539bf5;
  --vscode-symbolIcon-numberForeground: #57ab5a;
  --vscode-symbolIcon-objectForeground: #e0823d;
  --vscode-symbolIcon-operatorForeground: #6cb6ff;
  --vscode-symbolIcon-packageForeground: #e0823d;
  --vscode-symbolIcon-propertyForeground: #e0823d;
  --vscode-symbolIcon-referenceForeground: #539bf5;
  --vscode-symbolIcon-snippetForeground: #539bf5;
  --vscode-symbolIcon-stringForeground: #6cb6ff;
  --vscode-symbolIcon-structForeground: #e0823d;
  --vscode-symbolIcon-textForeground: #6cb6ff;
  --vscode-symbolIcon-typeParameterForeground: #6cb6ff;
  --vscode-symbolIcon-unitForeground: #539bf5;
  --vscode-symbolIcon-variableForeground: #e0823d;
  --vscode-editorHoverWidget-highlightForeground: #539bf5;
  --vscode-editorOverviewRuler-bracketMatchForeground: #a0a0a0;
  --vscode-editor-foldBackground: rgba(99, 110, 123, 0.1);
  --vscode-editorGutter-foldingControlForeground: #768390;
  --vscode-editor-linkedEditingBackground: rgba(83, 155, 245, 0.07);
  --vscode-editor-wordHighlightBackground: rgba(99, 110, 123, 0.5);
  --vscode-editor-wordHighlightStrongBackground: rgba(99, 110, 123, 0.3);
  --vscode-editor-wordHighlightBorder: rgba(99, 110, 123, 0.6);
  --vscode-editor-wordHighlightStrongBorder: rgba(99, 110, 123, 0.6);
  --vscode-editorOverviewRuler-wordHighlightForeground: rgba(160, 160, 160, 0.8);
  --vscode-editorOverviewRuler-wordHighlightStrongForeground: rgba(192, 160, 192, 0.8);
  --vscode-peekViewTitle-background: rgba(55, 148, 255, 0.1);
  --vscode-peekViewTitleLabel-foreground: #ffffff;
  --vscode-peekViewTitleDescription-foreground: rgba(204, 204, 204, 0.7);
  --vscode-peekView-border: #3794ff;
  --vscode-peekViewResult-background: #22272e;
  --vscode-peekViewResult-lineForeground: #bbbbbb;
  --vscode-peekViewResult-fileForeground: #ffffff;
  --vscode-peekViewResult-selectionBackground: rgba(51, 153, 255, 0.2);
  --vscode-peekViewResult-selectionForeground: #ffffff;
  --vscode-peekViewEditor-background: rgba(99, 110, 123, 0.1);
  --vscode-peekViewEditorGutter-background: rgba(99, 110, 123, 0.1);
  --vscode-peekViewResult-matchHighlightBackground: rgba(174, 124, 20, 0.4);
  --vscode-peekViewEditor-matchHighlightBackground: rgba(174, 124, 20, 0.4);
  --vscode-editorMarkerNavigationError-background: #f14c4c;
  --vscode-editorMarkerNavigationError-headerBackground: rgba(241, 76, 76, 0.1);
  --vscode-editorMarkerNavigationWarning-background: #cca700;
  --vscode-editorMarkerNavigationWarning-headerBackground: rgba(204, 167, 0, 0.1);
  --vscode-editorMarkerNavigationInfo-background: #3794ff;
  --vscode-editorMarkerNavigationInfo-headerBackground: rgba(55, 148, 255, 0.1);
  --vscode-editorMarkerNavigation-background: #22272e;
  --vscode-editorSuggestWidget-background: #2d333b;
  --vscode-editorSuggestWidget-border: #454545;
  --vscode-editorSuggestWidget-foreground: #adbac7;
  --vscode-editorSuggestWidget-selectedForeground: #adbac7;
  --vscode-editorSuggestWidget-selectedBackground: rgba(99, 110, 123, 0.4);
  --vscode-editorSuggestWidget-highlightForeground: #539bf5;
  --vscode-editorSuggestWidget-focusHighlightForeground: #539bf5;
  --vscode-editorSuggestWidgetStatus-foreground: rgba(173, 186, 199, 0.5);
  --vscode-tab-activeBackground: #22272e;
  --vscode-tab-unfocusedActiveBackground: #22272e;
  --vscode-tab-inactiveBackground: #1c2128;
  --vscode-tab-unfocusedInactiveBackground: #1c2128;
  --vscode-tab-activeForeground: #adbac7;
  --vscode-tab-inactiveForeground: #768390;
  --vscode-tab-unfocusedActiveForeground: rgba(173, 186, 199, 0.5);
  --vscode-tab-unfocusedInactiveForeground: rgba(118, 131, 144, 0.5);
  --vscode-tab-hoverBackground: #22272e;
  --vscode-tab-unfocusedHoverBackground: rgba(99, 110, 123, 0.1);
  --vscode-tab-border: #444c56;
  --vscode-tab-lastPinnedBorder: #373e47;
  --vscode-tab-activeBorder: #22272e;
  --vscode-tab-unfocusedActiveBorder: #22272e;
  --vscode-tab-activeBorderTop: #ec775c;
  --vscode-tab-unfocusedActiveBorderTop: #444c56;
  --vscode-tab-activeModifiedBorder: #3399cc;
  --vscode-tab-inactiveModifiedBorder: rgba(51, 153, 204, 0.5);
  --vscode-tab-unfocusedActiveModifiedBorder: rgba(51, 153, 204, 0.5);
  --vscode-tab-unfocusedInactiveModifiedBorder: rgba(51, 153, 204, 0.25);
  --vscode-editorPane-background: #22272e;
  --vscode-editorGroupHeader-tabsBackground: #1c2128;
  --vscode-editorGroupHeader-tabsBorder: #444c56;
  --vscode-editorGroupHeader-noTabsBackground: #22272e;
  --vscode-editorGroup-border: #444c56;
  --vscode-editorGroup-dropBackground: rgba(83, 89, 93, 0.5);
  --vscode-editorGroup-dropIntoPromptForeground: #adbac7;
  --vscode-editorGroup-dropIntoPromptBackground: #2d333b;
  --vscode-sideBySideEditor-horizontalBorder: #444c56;
  --vscode-sideBySideEditor-verticalBorder: #444c56;
  --vscode-panel-background: #1c2128;
  --vscode-panel-border: #444c56;
  --vscode-panelTitle-activeForeground: #adbac7;
  --vscode-panelTitle-inactiveForeground: #768390;
  --vscode-panelTitle-activeBorder: #ec775c;
  --vscode-panelInput-border: #444c56;
  --vscode-panel-dropBorder: #adbac7;
  --vscode-panelSection-dropBackground: rgba(83, 89, 93, 0.5);
  --vscode-panelSectionHeader-background: rgba(128, 128, 128, 0.2);
  --vscode-panelSection-border: #444c56;
  --vscode-banner-background: rgba(99, 110, 123, 0.4);
  --vscode-banner-foreground: #adbac7;
  --vscode-banner-iconForeground: #3794ff;
  --vscode-statusBar-foreground: #768390;
  --vscode-statusBar-noFolderForeground: #768390;
  --vscode-statusBar-background: #22272e;
  --vscode-statusBar-noFolderBackground: #22272e;
  --vscode-statusBar-border: #444c56;
  --vscode-statusBar-focusBorder: rgba(49, 109, 202, 0.5);
  --vscode-statusBar-noFolderBorder: #444c56;
  --vscode-statusBarItem-activeBackground: rgba(173, 186, 199, 0.12);
  --vscode-statusBarItem-focusBorder: #316dca;
  --vscode-statusBarItem-hoverBackground: rgba(173, 186, 199, 0.08);
  --vscode-statusBarItem-compactHoverBackground: rgba(255, 255, 255, 0.2);
  --vscode-statusBarItem-prominentForeground: #768390;
  --vscode-statusBarItem-prominentBackground: rgba(99, 110, 123, 0.4);
  --vscode-statusBarItem-prominentHoverBackground: rgba(0, 0, 0, 0.3);
  --vscode-statusBarItem-errorBackground: #a01e17;
  --vscode-statusBarItem-errorForeground: #ffffff;
  --vscode-statusBarItem-warningBackground: #7a6400;
  --vscode-statusBarItem-warningForeground: #ffffff;
  --vscode-activityBar-background: #22272e;
  --vscode-activityBar-foreground: #adbac7;
  --vscode-activityBar-inactiveForeground: #768390;
  --vscode-activityBar-border: #444c56;
  --vscode-activityBar-activeBorder: #ec775c;
  --vscode-activityBar-dropBorder: #adbac7;
  --vscode-activityBarBadge-background: #316dca;
  --vscode-activityBarBadge-foreground: #cdd9e5;
  --vscode-statusBarItem-remoteBackground: #444c56;
  --vscode-statusBarItem-remoteForeground: #adbac7;
  --vscode-extensionBadge-remoteBackground: #316dca;
  --vscode-extensionBadge-remoteForeground: #cdd9e5;
  --vscode-sideBar-background: #1c2128;
  --vscode-sideBar-foreground: #adbac7;
  --vscode-sideBar-border: #444c56;
  --vscode-sideBarTitle-foreground: #adbac7;
  --vscode-sideBar-dropBackground: rgba(83, 89, 93, 0.5);
  --vscode-sideBarSectionHeader-background: #1c2128;
  --vscode-sideBarSectionHeader-foreground: #adbac7;
  --vscode-sideBarSectionHeader-border: #444c56;
  --vscode-titleBar-activeForeground: #768390;
  --vscode-titleBar-inactiveForeground: #768390;
  --vscode-titleBar-activeBackground: #22272e;
  --vscode-titleBar-inactiveBackground: #1c2128;
  --vscode-titleBar-border: #444c56;
  --vscode-menubar-selectionForeground: #768390;
  --vscode-menubar-selectionBackground: rgba(90, 93, 94, 0.31);
  --vscode-notifications-foreground: #adbac7;
  --vscode-notifications-background: #2d333b;
  --vscode-notificationLink-foreground: #539bf5;
  --vscode-notificationCenterHeader-foreground: #768390;
  --vscode-notificationCenterHeader-background: #2d333b;
  --vscode-notifications-border: #444c56;
  --vscode-notificationsErrorIcon-foreground: #e5534b;
  --vscode-notificationsWarningIcon-foreground: #c69026;
  --vscode-notificationsInfoIcon-foreground: #539bf5;
  --vscode-commandCenter-foreground: #768390;
  --vscode-commandCenter-activeForeground: #768390;
  --vscode-commandCenter-activeBackground: rgba(90, 93, 94, 0.31);
  --vscode-commandCenter-border: #444c56;
  --vscode-editorCommentsWidget-resolvedBorder: rgba(204, 204, 204, 0.5);
  --vscode-editorCommentsWidget-unresolvedBorder: #3794ff;
  --vscode-editorCommentsWidget-rangeBackground: rgba(55, 148, 255, 0.1);
  --vscode-editorCommentsWidget-rangeBorder: rgba(55, 148, 255, 0.4);
  --vscode-editorCommentsWidget-rangeActiveBackground: rgba(55, 148, 255, 0.1);
  --vscode-editorCommentsWidget-rangeActiveBorder: rgba(55, 148, 255, 0.4);
  --vscode-editorGutter-commentRangeForeground: rgba(99, 110, 123, 0.4);
  --vscode-debugToolBar-background: #2d333b;
  --vscode-debugIcon-startForeground: #89d185;
  --vscode-editor-stackFrameHighlightBackground: rgba(174, 124, 20, 0.4);
  --vscode-editor-focusedStackFrameHighlightBackground: rgba(70, 149, 74, 0.4);
  --vscode-mergeEditor-change\.background: rgba(155, 185, 85, 0.2);
  --vscode-mergeEditor-change\.word\.background: rgba(156, 204, 44, 0.2);
  --vscode-mergeEditor-conflict\.unhandledUnfocused\.border: rgba(255, 166, 0, 0.48);
  --vscode-mergeEditor-conflict\.unhandledFocused\.border: #ffa600;
  --vscode-mergeEditor-conflict\.handledUnfocused\.border: rgba(134, 134, 134, 0.29);
  --vscode-mergeEditor-conflict\.handledFocused\.border: rgba(193, 193, 193, 0.8);
  --vscode-mergeEditor-conflict\.handled\.minimapOverViewRuler: rgba(173, 172, 168, 0.93);
  --vscode-mergeEditor-conflict\.unhandled\.minimapOverViewRuler: #fcba03;
  --vscode-mergeEditor-conflictingLines\.background: rgba(255, 234, 0, 0.28);
  --vscode-settings-headerForeground: #768390;
  --vscode-settings-modifiedItemIndicator: rgba(174, 124, 20, 0.4);
  --vscode-settings-headerBorder: #444c56;
  --vscode-settings-sashBorder: #444c56;
  --vscode-settings-dropdownBackground: #2d333b;
  --vscode-settings-dropdownForeground: #adbac7;
  --vscode-settings-dropdownBorder: #444c56;
  --vscode-settings-dropdownListBorder: #454545;
  --vscode-settings-checkboxBackground: #2d333b;
  --vscode-settings-checkboxForeground: #adbac7;
  --vscode-settings-checkboxBorder: #444c56;
  --vscode-settings-textInputBackground: #22272e;
  --vscode-settings-textInputForeground: #adbac7;
  --vscode-settings-textInputBorder: #444c56;
  --vscode-settings-numberInputBackground: #22272e;
  --vscode-settings-numberInputForeground: #adbac7;
  --vscode-settings-numberInputBorder: #444c56;
  --vscode-settings-focusedRowBackground: rgba(99, 110, 123, 0.06);
  --vscode-settings-rowHoverBackground: rgba(99, 110, 123, 0.03);
  --vscode-settings-focusedRowBorder: rgba(255, 255, 255, 0.12);
  --vscode-terminal-foreground: #adbac7;
  --vscode-terminal-selectionBackground: #264f78;
  --vscode-terminal-inactiveSelectionBackground: rgba(38, 79, 120, 0.5);
  --vscode-terminalCommandDecoration-defaultBackground: rgba(255, 255, 255, 0.25);
  --vscode-terminalCommandDecoration-successBackground: #1b81a8;
  --vscode-terminalCommandDecoration-errorBackground: #f14c4c;
  --vscode-terminalOverviewRuler-cursorForeground: rgba(160, 160, 160, 0.8);
  --vscode-terminal-border: #444c56;
  --vscode-terminal-findMatchBackground: #966600;
  --vscode-terminal-findMatchHighlightBackground: rgba(234, 197, 95, 0.5);
  --vscode-terminalOverviewRuler-findMatchForeground: rgba(209, 134, 22, 0.49);
  --vscode-terminal-dropBackground: rgba(83, 89, 93, 0.5);
  --vscode-terminal-tab\.activeBorder: #22272e;
  --vscode-testing-iconFailed: #f14c4c;
  --vscode-testing-iconErrored: #f14c4c;
  --vscode-testing-iconPassed: #73c991;
  --vscode-testing-runAction: #73c991;
  --vscode-testing-iconQueued: #cca700;
  --vscode-testing-iconUnset: #848484;
  --vscode-testing-iconSkipped: #848484;
  --vscode-testing-peekBorder: #f14c4c;
  --vscode-testing-peekHeaderBackground: rgba(241, 76, 76, 0.1);
  --vscode-testing-message\.error\.decorationForeground: #f14c4c;
  --vscode-testing-message\.error\.lineBackground: rgba(255, 0, 0, 0.2);
  --vscode-testing-message\.info\.decorationForeground: rgba(173, 186, 199, 0.5);
  --vscode-welcomePage-tileBackground: #2d333b;
  --vscode-welcomePage-tileHoverBackground: #363d47;
  --vscode-welcomePage-tileShadow: rgba(0, 0, 0, 0.36);
  --vscode-welcomePage-progress\.background: #22272e;
  --vscode-welcomePage-progress\.foreground: #539bf5;
  --vscode-debugExceptionWidget-border: #a31515;
  --vscode-debugExceptionWidget-background: #420b0d;
  --vscode-ports-iconRunningProcessForeground: #444c56;
  --vscode-statusBar-debuggingBackground: #c93c37;
  --vscode-statusBar-debuggingForeground: #cdd9e5;
  --vscode-statusBar-debuggingBorder: #444c56;
  --vscode-editor-inlineValuesForeground: rgba(255, 255, 255, 0.5);
  --vscode-editor-inlineValuesBackground: rgba(255, 200, 0, 0.2);
  --vscode-editorGutter-modifiedBackground: rgba(174, 124, 20, 0.4);
  --vscode-editorGutter-addedBackground: rgba(70, 149, 74, 0.4);
  --vscode-editorGutter-deletedBackground: rgba(229, 83, 75, 0.4);
  --vscode-minimapGutter-modifiedBackground: rgba(174, 124, 20, 0.4);
  --vscode-minimapGutter-addedBackground: rgba(70, 149, 74, 0.4);
  --vscode-minimapGutter-deletedBackground: rgba(229, 83, 75, 0.4);
  --vscode-editorOverviewRuler-modifiedForeground: rgba(174, 124, 20, 0.24);
  --vscode-editorOverviewRuler-addedForeground: rgba(70, 149, 74, 0.24);
  --vscode-editorOverviewRuler-deletedForeground: rgba(229, 83, 75, 0.24);
  --vscode-debugIcon-breakpointForeground: #e5534b;
  --vscode-debugIcon-breakpointDisabledForeground: #848484;
  --vscode-debugIcon-breakpointUnverifiedForeground: #848484;
  --vscode-debugIcon-breakpointCurrentStackframeForeground: #ffcc00;
  --vscode-debugIcon-breakpointStackframeForeground: #89d185;
  --vscode-notebook-cellBorderColor: rgba(99, 110, 123, 0.4);
  --vscode-notebook-focusedEditorBorder: #316dca;
  --vscode-notebookStatusSuccessIcon-foreground: #89d185;
  --vscode-notebookStatusErrorIcon-foreground: #e5534b;
  --vscode-notebookStatusRunningIcon-foreground: #adbac7;
  --vscode-notebook-cellToolbarSeparator: rgba(128, 128, 128, 0.35);
  --vscode-notebook-selectedCellBackground: rgba(99, 110, 123, 0.4);
  --vscode-notebook-selectedCellBorder: rgba(99, 110, 123, 0.4);
  --vscode-notebook-focusedCellBorder: #316dca;
  --vscode-notebook-inactiveFocusedCellBorder: rgba(99, 110, 123, 0.4);
  --vscode-notebook-cellStatusBarItemHoverBackground: rgba(255, 255, 255, 0.15);
  --vscode-notebook-cellInsertionIndicator: #316dca;
  --vscode-notebookScrollbarSlider-background: rgba(99, 110, 123, 0.2);
  --vscode-notebookScrollbarSlider-hoverBackground: rgba(99, 110, 123, 0.27);
  --vscode-notebookScrollbarSlider-activeBackground: rgba(99, 110, 123, 0.53);
  --vscode-notebook-symbolHighlightBackground: rgba(255, 255, 255, 0.04);
  --vscode-notebook-cellEditorBackground: #1c2128;
  --vscode-notebook-editorBackground: #22272e;
  --vscode-keybindingTable-headerBackground: rgba(173, 186, 199, 0.04);
  --vscode-keybindingTable-rowsBackground: rgba(173, 186, 199, 0.04);
  --vscode-scm-providerBorder: #454545;
  --vscode-searchEditor-textInputBorder: #444c56;
  --vscode-debugTokenExpression-name: #6cb6ff;
  --vscode-debugTokenExpression-value: #96d0ff;
  --vscode-debugTokenExpression-string: #96d0ff;
  --vscode-debugTokenExpression-boolean: #6bc46d;
  --vscode-debugTokenExpression-number: #6bc46d;
  --vscode-debugTokenExpression-error: #ff938a;
  --vscode-debugView-exceptionLabelForeground: #adbac7;
  --vscode-debugView-exceptionLabelBackground: #6c2022;
  --vscode-debugView-stateLabelForeground: #adbac7;
  --vscode-debugView-stateLabelBackground: rgba(136, 136, 136, 0.27);
  --vscode-debugView-valueChangedHighlight: #569cd6;
  --vscode-debugConsole-infoForeground: #768390;
  --vscode-debugConsole-warningForeground: #c69026;
  --vscode-debugConsole-errorForeground: #ff938a;
  --vscode-debugConsole-sourceForeground: #daaa3f;
  --vscode-debugConsoleInputIcon-foreground: #b083f0;
  --vscode-debugIcon-pauseForeground: #75beff;
  --vscode-debugIcon-stopForeground: #f48771;
  --vscode-debugIcon-disconnectForeground: #f48771;
  --vscode-debugIcon-restartForeground: #89d185;
  --vscode-debugIcon-stepOverForeground: #75beff;
  --vscode-debugIcon-stepIntoForeground: #75beff;
  --vscode-debugIcon-stepOutForeground: #75beff;
  --vscode-debugIcon-continueForeground: #75beff;
  --vscode-debugIcon-stepBackForeground: #75beff;
  --vscode-extensionButton-prominentBackground: #347d39;
  --vscode-extensionButton-prominentForeground: #ffffff;
  --vscode-extensionButton-prominentHoverBackground: #46954a;
  --vscode-extensionIcon-starForeground: #ff8e00;
  --vscode-extensionIcon-verifiedForeground: #539bf5;
  --vscode-extensionIcon-preReleaseForeground: #1d9271;
  --vscode-extensionIcon-sponsorForeground: #d758b3;
  --vscode-terminal-ansiBlack: #545d68;
  --vscode-terminal-ansiRed: #f47067;
  --vscode-terminal-ansiGreen: #57ab5a;
  --vscode-terminal-ansiYellow: #c69026;
  --vscode-terminal-ansiBlue: #539bf5;
  --vscode-terminal-ansiMagenta: #b083f0;
  --vscode-terminal-ansiCyan: #39c5cf;
  --vscode-terminal-ansiWhite: #909dab;
  --vscode-terminal-ansiBrightBlack: #636e7b;
  --vscode-terminal-ansiBrightRed: #ff938a;
  --vscode-terminal-ansiBrightGreen: #6bc46d;
  --vscode-terminal-ansiBrightYellow: #daaa3f;
  --vscode-terminal-ansiBrightBlue: #6cb6ff;
  --vscode-terminal-ansiBrightMagenta: #dcbdfb;
  --vscode-terminal-ansiBrightCyan: #56d4dd;
  --vscode-terminal-ansiBrightWhite: #cdd9e5;
  --vscode-interactive-activeCodeBorder: #3794ff;
  --vscode-interactive-inactiveCodeBorder: rgba(99, 110, 123, 0.4);
  --vscode-gitDecoration-addedResourceForeground: #57ab5a;
  --vscode-gitDecoration-modifiedResourceForeground: #c69026;
  --vscode-gitDecoration-deletedResourceForeground: #e5534b;
  --vscode-gitDecoration-renamedResourceForeground: #73c991;
  --vscode-gitDecoration-untrackedResourceForeground: #57ab5a;
  --vscode-gitDecoration-ignoredResourceForeground: #545d68;
  --vscode-gitDecoration-stageModifiedResourceForeground: #e2c08d;
  --vscode-gitDecoration-stageDeletedResourceForeground: #c74e39;
  --vscode-gitDecoration-conflictingResourceForeground: #cc6b2c;
  --vscode-gitDecoration-submoduleResourceForeground: #768390;
}
:root {
  --vscode-font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  --vscode-font-weight: normal;
  --vscode-font-size: 13px;
  --vscode-editor-font-family: Menlo, Monaco, "Courier New", monospace;
  --vscode-editor-font-weight: normal;
  --vscode-editor-font-size: 12px;
  --vscode-foreground: #616161;
  --vscode-disabledForeground: rgba(97, 97, 97, 0.5);
  --vscode-errorForeground: #a1260d;
  --vscode-descriptionForeground: #717171;
  --vscode-icon-foreground: #424242;
  --vscode-focusBorder: #0090f1;
  --vscode-textSeparator-foreground: rgba(0, 0, 0, 0.18);
  --vscode-textLink-foreground: #006ab1;
  --vscode-textLink-activeForeground: #006ab1;
  --vscode-textPreformat-foreground: #a31515;
  --vscode-textBlockQuote-background: rgba(127, 127, 127, 0.1);
  --vscode-textBlockQuote-border: rgba(0, 122, 204, 0.5);
  --vscode-textCodeBlock-background: rgba(220, 220, 220, 0.4);
  --vscode-widget-shadow: rgba(0, 0, 0, 0.16);
  --vscode-input-background: #ffffff;
  --vscode-input-foreground: #616161;
  --vscode-inputOption-activeBorder: rgba(0, 122, 204, 0);
  --vscode-inputOption-hoverBackground: rgba(184, 184, 184, 0.31);
  --vscode-inputOption-activeBackground: rgba(0, 144, 241, 0.2);
  --vscode-inputOption-activeForeground: #000000;
  --vscode-input-placeholderForeground: rgba(97, 97, 97, 0.5);
  --vscode-inputValidation-infoBackground: #d6ecf2;
  --vscode-inputValidation-infoBorder: #007acc;
  --vscode-inputValidation-warningBackground: #f6f5d2;
  --vscode-inputValidation-warningBorder: #b89500;
  --vscode-inputValidation-errorBackground: #f2dede;
  --vscode-inputValidation-errorBorder: #be1100;
  --vscode-dropdown-background: #ffffff;
  --vscode-dropdown-border: #cecece;
  --vscode-checkbox-background: #ffffff;
  --vscode-checkbox-border: #cecece;
  --vscode-button-foreground: #ffffff;
  --vscode-button-separator: rgba(255, 255, 255, 0.4);
  --vscode-button-background: #007acc;
  --vscode-button-hoverBackground: #0062a3;
  --vscode-button-secondaryForeground: #ffffff;
  --vscode-button-secondaryBackground: #5f6a79;
  --vscode-button-secondaryHoverBackground: #4c5561;
  --vscode-badge-background: #c4c4c4;
  --vscode-badge-foreground: #333333;
  --vscode-scrollbar-shadow: #dddddd;
  --vscode-scrollbarSlider-background: rgba(100, 100, 100, 0.4);
  --vscode-scrollbarSlider-hoverBackground: rgba(100, 100, 100, 0.7);
  --vscode-scrollbarSlider-activeBackground: rgba(0, 0, 0, 0.6);
  --vscode-progressBar-background: #0e70c0;
  --vscode-editorError-foreground: #e51400;
  --vscode-editorWarning-foreground: #bf8803;
  --vscode-editorInfo-foreground: #1a85ff;
  --vscode-editorHint-foreground: #6c6c6c;
  --vscode-sash-hoverBorder: #0090f1;
  --vscode-editor-background: #fafafa;
  --vscode-editor-foreground: #333333;
  --vscode-editorStickyScroll-background: #fafafa;
  --vscode-editorStickyScrollHover-background: #f0f0f0;
  --vscode-editorWidget-background: #f3f3f3;
  --vscode-editorWidget-foreground: #616161;
  --vscode-editorWidget-border: #c8c8c8;
  --vscode-quickInput-background: #f3f3f3;
  --vscode-quickInput-foreground: #616161;
  --vscode-quickInputTitle-background: rgba(0, 0, 0, 0.06);
  --vscode-pickerGroup-foreground: #0066bf;
  --vscode-pickerGroup-border: #cccedb;
  --vscode-keybindingLabel-background: rgba(221, 221, 221, 0.4);
  --vscode-keybindingLabel-foreground: #555555;
  --vscode-keybindingLabel-border: rgba(204, 204, 204, 0.4);
  --vscode-keybindingLabel-bottomBorder: rgba(187, 187, 187, 0.4);
  --vscode-editor-selectionBackground: #ccc9ad;
  --vscode-editor-inactiveSelectionBackground: rgba(204, 201, 173, 0.5);
  --vscode-editor-selectionHighlightBackground: rgba(221, 219, 201, 0.6);
  --vscode-editor-findMatchBackground: #a8ac94;
  --vscode-editor-findMatchHighlightBackground: rgba(234, 92, 0, 0.33);
  --vscode-editor-findRangeHighlightBackground: rgba(180, 180, 180, 0.3);
  --vscode-searchEditor-findMatchBackground: rgba(234, 92, 0, 0.22);
  --vscode-editor-hoverHighlightBackground: rgba(173, 214, 255, 0.15);
  --vscode-editorHoverWidget-background: #f3f3f3;
  --vscode-editorHoverWidget-foreground: #616161;
  --vscode-editorHoverWidget-border: #c8c8c8;
  --vscode-editorHoverWidget-statusBarBackground: #e7e7e7;
  --vscode-editorLink-activeForeground: #0000ff;
  --vscode-editorInlayHint-foreground: rgba(51, 51, 51, 0.8);
  --vscode-editorInlayHint-background: rgba(196, 196, 196, 0.3);
  --vscode-editorInlayHint-typeForeground: rgba(51, 51, 51, 0.8);
  --vscode-editorInlayHint-typeBackground: rgba(196, 196, 196, 0.3);
  --vscode-editorInlayHint-parameterForeground: rgba(51, 51, 51, 0.8);
  --vscode-editorInlayHint-parameterBackground: rgba(196, 196, 196, 0.3);
  --vscode-editorLightBulb-foreground: #ddb100;
  --vscode-editorLightBulbAutoFix-foreground: #007acc;
  --vscode-diffEditor-insertedTextBackground: rgba(156, 204, 44, 0.4);
  --vscode-diffEditor-removedTextBackground: rgba(255, 0, 0, 0.3);
  --vscode-diffEditor-insertedLineBackground: rgba(155, 185, 85, 0.2);
  --vscode-diffEditor-removedLineBackground: rgba(255, 0, 0, 0.2);
  --vscode-diffEditor-diagonalFill: rgba(34, 34, 34, 0.2);
  --vscode-list-focusOutline: #0090f1;
  --vscode-list-activeSelectionBackground: #0060c0;
  --vscode-list-activeSelectionForeground: #ffffff;
  --vscode-list-inactiveSelectionBackground: #e4e6f1;
  --vscode-list-hoverBackground: #f0f0f0;
  --vscode-list-dropBackground: #d6ebff;
  --vscode-list-highlightForeground: #0066bf;
  --vscode-list-focusHighlightForeground: #bbe7ff;
  --vscode-list-invalidItemForeground: #b89500;
  --vscode-list-errorForeground: #b01011;
  --vscode-list-warningForeground: #855f00;
  --vscode-listFilterWidget-background: #f3f3f3;
  --vscode-listFilterWidget-outline: rgba(0, 0, 0, 0);
  --vscode-listFilterWidget-noMatchesOutline: #be1100;
  --vscode-listFilterWidget-shadow: rgba(0, 0, 0, 0.16);
  --vscode-list-filterMatchBackground: rgba(234, 92, 0, 0.33);
  --vscode-tree-indentGuidesStroke: #a9a9a9;
  --vscode-tree-tableColumnsBorder: rgba(97, 97, 97, 0.13);
  --vscode-tree-tableOddRowsBackground: rgba(97, 97, 97, 0.04);
  --vscode-list-deemphasizedForeground: #8e8e90;
  --vscode-quickInputList-focusForeground: #ffffff;
  --vscode-quickInputList-focusBackground: #0060c0;
  --vscode-menu-foreground: #616161;
  --vscode-menu-background: #ffffff;
  --vscode-menu-selectionForeground: #ffffff;
  --vscode-menu-selectionBackground: #0060c0;
  --vscode-menu-separatorBackground: #d4d4d4;
  --vscode-toolbar-hoverBackground: rgba(184, 184, 184, 0.31);
  --vscode-toolbar-activeBackground: rgba(166, 166, 166, 0.31);
  --vscode-editor-snippetTabstopHighlightBackground: rgba(10, 50, 100, 0.2);
  --vscode-editor-snippetFinalTabstopHighlightBorder: rgba(10, 50, 100, 0.5);
  --vscode-breadcrumb-foreground: rgba(97, 97, 97, 0.8);
  --vscode-breadcrumb-background: #fafafa;
  --vscode-breadcrumb-focusForeground: #4e4e4e;
  --vscode-breadcrumb-activeSelectionForeground: #4e4e4e;
  --vscode-breadcrumbPicker-background: #f3f3f3;
  --vscode-merge-currentHeaderBackground: rgba(64, 200, 174, 0.5);
  --vscode-merge-currentContentBackground: rgba(64, 200, 174, 0.2);
  --vscode-merge-incomingHeaderBackground: rgba(64, 166, 255, 0.5);
  --vscode-merge-incomingContentBackground: rgba(64, 166, 255, 0.2);
  --vscode-merge-commonHeaderBackground: rgba(96, 96, 96, 0.4);
  --vscode-merge-commonContentBackground: rgba(96, 96, 96, 0.16);
  --vscode-editorOverviewRuler-currentContentForeground: rgba(64, 200, 174, 0.5);
  --vscode-editorOverviewRuler-incomingContentForeground: rgba(64, 166, 255, 0.5);
  --vscode-editorOverviewRuler-commonContentForeground: rgba(96, 96, 96, 0.4);
  --vscode-editorOverviewRuler-findMatchForeground: rgba(209, 134, 22, 0.49);
  --vscode-editorOverviewRuler-selectionHighlightForeground: rgba(160, 160, 160, 0.8);
  --vscode-minimap-findMatchHighlight: #d18616;
  --vscode-minimap-selectionOccurrenceHighlight: #c9c9c9;
  --vscode-minimap-selectionHighlight: #add6ff;
  --vscode-minimap-errorHighlight: rgba(255, 18, 18, 0.7);
  --vscode-minimap-warningHighlight: #bf8803;
  --vscode-minimap-foregroundOpacity: #000000;
  --vscode-minimapSlider-background: rgba(100, 100, 100, 0.2);
  --vscode-minimapSlider-hoverBackground: rgba(100, 100, 100, 0.35);
  --vscode-minimapSlider-activeBackground: rgba(0, 0, 0, 0.3);
  --vscode-problemsErrorIcon-foreground: #e51400;
  --vscode-problemsWarningIcon-foreground: #bf8803;
  --vscode-problemsInfoIcon-foreground: #1a85ff;
  --vscode-charts-foreground: #616161;
  --vscode-charts-lines: rgba(97, 97, 97, 0.5);
  --vscode-charts-red: #e51400;
  --vscode-charts-blue: #1a85ff;
  --vscode-charts-yellow: #bf8803;
  --vscode-charts-orange: #d18616;
  --vscode-charts-green: #388a34;
  --vscode-charts-purple: #652d90;
  --vscode-editor-lineHighlightBackground: #e6e3c3;
  --vscode-editor-lineHighlightBorder: #eeeeee;
  --vscode-editor-rangeHighlightBackground: rgba(253, 255, 0, 0.2);
  --vscode-editor-symbolHighlightBackground: rgba(234, 92, 0, 0.33);
  --vscode-editorCursor-foreground: #666663;
  --vscode-editorWhitespace-foreground: #bbbbb7;
  --vscode-editorIndentGuide-background: #d3d4d5;
  --vscode-editorIndentGuide-activeBackground: #bbbbb7;
  --vscode-editorLineNumber-foreground: #a2a19c;
  --vscode-editorActiveLineNumber-foreground: #0b216f;
  --vscode-editorLineNumber-activeForeground: #0b216f;
  --vscode-editorRuler-foreground: #d3d3d3;
  --vscode-editorCodeLens-foreground: #919191;
  --vscode-editorBracketMatch-background: rgba(0, 100, 0, 0.1);
  --vscode-editorBracketMatch-border: #b9b9b9;
  --vscode-editorOverviewRuler-border: rgba(127, 127, 127, 0.3);
  --vscode-editorGutter-background: #fafafa;
  --vscode-editorUnnecessaryCode-opacity: rgba(0, 0, 0, 0.47);
  --vscode-editorGhostText-foreground: rgba(0, 0, 0, 0.47);
  --vscode-editorOverviewRuler-rangeHighlightForeground: rgba(0, 122, 204, 0.6);
  --vscode-editorOverviewRuler-errorForeground: rgba(255, 18, 18, 0.7);
  --vscode-editorOverviewRuler-warningForeground: #bf8803;
  --vscode-editorOverviewRuler-infoForeground: #1a85ff;
  --vscode-editorBracketHighlight-foreground1: #0431fa;
  --vscode-editorBracketHighlight-foreground2: #319331;
  --vscode-editorBracketHighlight-foreground3: #7b3814;
  --vscode-editorBracketHighlight-foreground4: rgba(0, 0, 0, 0);
  --vscode-editorBracketHighlight-foreground5: rgba(0, 0, 0, 0);
  --vscode-editorBracketHighlight-foreground6: rgba(0, 0, 0, 0);
  --vscode-editorBracketHighlight-unexpectedBracket\.foreground: rgba(255, 18, 18, 0.8);
  --vscode-editorBracketPairGuide-background1: rgba(0, 0, 0, 0);
  --vscode-editorBracketPairGuide-background2: rgba(0, 0, 0, 0);
  --vscode-editorBracketPairGuide-background3: rgba(0, 0, 0, 0);
  --vscode-editorBracketPairGuide-background4: rgba(0, 0, 0, 0);
  --vscode-editorBracketPairGuide-background5: rgba(0, 0, 0, 0);
  --vscode-editorBracketPairGuide-background6: rgba(0, 0, 0, 0);
  --vscode-editorBracketPairGuide-activeBackground1: rgba(0, 0, 0, 0);
  --vscode-editorBracketPairGuide-activeBackground2: rgba(0, 0, 0, 0);
  --vscode-editorBracketPairGuide-activeBackground3: rgba(0, 0, 0, 0);
  --vscode-editorBracketPairGuide-activeBackground4: rgba(0, 0, 0, 0);
  --vscode-editorBracketPairGuide-activeBackground5: rgba(0, 0, 0, 0);
  --vscode-editorBracketPairGuide-activeBackground6: rgba(0, 0, 0, 0);
  --vscode-editorUnicodeHighlight-border: #cea33d;
  --vscode-editorUnicodeHighlight-background: rgba(206, 163, 61, 0.08);
  --vscode-symbolIcon-arrayForeground: #616161;
  --vscode-symbolIcon-booleanForeground: #616161;
  --vscode-symbolIcon-classForeground: #d67e00;
  --vscode-symbolIcon-colorForeground: #616161;
  --vscode-symbolIcon-constantForeground: #616161;
  --vscode-symbolIcon-constructorForeground: #652d90;
  --vscode-symbolIcon-enumeratorForeground: #d67e00;
  --vscode-symbolIcon-enumeratorMemberForeground: #007acc;
  --vscode-symbolIcon-eventForeground: #d67e00;
  --vscode-symbolIcon-fieldForeground: #007acc;
  --vscode-symbolIcon-fileForeground: #616161;
  --vscode-symbolIcon-folderForeground: #616161;
  --vscode-symbolIcon-functionForeground: #652d90;
  --vscode-symbolIcon-interfaceForeground: #007acc;
  --vscode-symbolIcon-keyForeground: #616161;
  --vscode-symbolIcon-keywordForeground: #616161;
  --vscode-symbolIcon-methodForeground: #652d90;
  --vscode-symbolIcon-moduleForeground: #616161;
  --vscode-symbolIcon-namespaceForeground: #616161;
  --vscode-symbolIcon-nullForeground: #616161;
  --vscode-symbolIcon-numberForeground: #616161;
  --vscode-symbolIcon-objectForeground: #616161;
  --vscode-symbolIcon-operatorForeground: #616161;
  --vscode-symbolIcon-packageForeground: #616161;
  --vscode-symbolIcon-propertyForeground: #616161;
  --vscode-symbolIcon-referenceForeground: #616161;
  --vscode-symbolIcon-snippetForeground: #616161;
  --vscode-symbolIcon-stringForeground: #616161;
  --vscode-symbolIcon-structForeground: #616161;
  --vscode-symbolIcon-textForeground: #616161;
  --vscode-symbolIcon-typeParameterForeground: #616161;
  --vscode-symbolIcon-unitForeground: #616161;
  --vscode-symbolIcon-variableForeground: #007acc;
  --vscode-editorHoverWidget-highlightForeground: #0066bf;
  --vscode-editorOverviewRuler-bracketMatchForeground: #a0a0a0;
  --vscode-editor-foldBackground: rgba(204, 201, 173, 0.3);
  --vscode-editorGutter-foldingControlForeground: #424242;
  --vscode-editor-linkedEditingBackground: rgba(255, 0, 0, 0.3);
  --vscode-editor-wordHighlightBackground: rgba(87, 87, 87, 0.25);
  --vscode-editor-wordHighlightStrongBackground: rgba(14, 99, 156, 0.25);
  --vscode-editorOverviewRuler-wordHighlightForeground: rgba(160, 160, 160, 0.8);
  --vscode-editorOverviewRuler-wordHighlightStrongForeground: rgba(192, 160, 192, 0.8);
  --vscode-peekViewTitle-background: rgba(26, 133, 255, 0.1);
  --vscode-peekViewTitleLabel-foreground: #000000;
  --vscode-peekViewTitleDescription-foreground: #616161;
  --vscode-peekView-border: #1a85ff;
  --vscode-peekViewResult-background: #f3f3f3;
  --vscode-peekViewResult-lineForeground: #646465;
  --vscode-peekViewResult-fileForeground: #1e1e1e;
  --vscode-peekViewResult-selectionBackground: rgba(51, 153, 255, 0.2);
  --vscode-peekViewResult-selectionForeground: #6c6c6c;
  --vscode-peekViewEditor-background: #f2f8fc;
  --vscode-peekViewEditorGutter-background: #f2f8fc;
  --vscode-peekViewResult-matchHighlightBackground: rgba(234, 92, 0, 0.3);
  --vscode-peekViewEditor-matchHighlightBackground: rgba(245, 216, 2, 0.87);
  --vscode-editorMarkerNavigationError-background: #e51400;
  --vscode-editorMarkerNavigationError-headerBackground: rgba(229, 20, 0, 0.1);
  --vscode-editorMarkerNavigationWarning-background: #bf8803;
  --vscode-editorMarkerNavigationWarning-headerBackground: rgba(191, 136, 3, 0.1);
  --vscode-editorMarkerNavigationInfo-background: #1a85ff;
  --vscode-editorMarkerNavigationInfo-headerBackground: rgba(26, 133, 255, 0.1);
  --vscode-editorMarkerNavigation-background: #fafafa;
  --vscode-editorSuggestWidget-background: #f3f3f3;
  --vscode-editorSuggestWidget-border: #c8c8c8;
  --vscode-editorSuggestWidget-foreground: #333333;
  --vscode-editorSuggestWidget-selectedForeground: #ffffff;
  --vscode-editorSuggestWidget-selectedBackground: #0060c0;
  --vscode-editorSuggestWidget-highlightForeground: #0066bf;
  --vscode-editorSuggestWidget-focusHighlightForeground: #bbe7ff;
  --vscode-editorSuggestWidgetStatus-foreground: rgba(51, 51, 51, 0.5);
  --vscode-tab-activeBackground: #fafafa;
  --vscode-tab-unfocusedActiveBackground: #fafafa;
  --vscode-tab-inactiveBackground: #ececec;
  --vscode-tab-unfocusedInactiveBackground: #ececec;
  --vscode-tab-activeForeground: #333333;
  --vscode-tab-inactiveForeground: rgba(51, 51, 51, 0.7);
  --vscode-tab-unfocusedActiveForeground: rgba(51, 51, 51, 0.7);
  --vscode-tab-unfocusedInactiveForeground: rgba(51, 51, 51, 0.35);
  --vscode-tab-border: #f3f3f3;
  --vscode-tab-lastPinnedBorder: #a9a9a9;
  --vscode-tab-activeModifiedBorder: #33aaee;
  --vscode-tab-inactiveModifiedBorder: rgba(51, 170, 238, 0.5);
  --vscode-tab-unfocusedActiveModifiedBorder: rgba(51, 170, 238, 0.7);
  --vscode-tab-unfocusedInactiveModifiedBorder: rgba(51, 170, 238, 0.25);
  --vscode-editorPane-background: #fafafa;
  --vscode-editorGroupHeader-tabsBackground: #f3f3f3;
  --vscode-editorGroupHeader-noTabsBackground: #fafafa;
  --vscode-editorGroup-border: #e7e7e7;
  --vscode-editorGroup-dropBackground: rgba(38, 119, 203, 0.18);
  --vscode-editorGroup-dropIntoPromptForeground: #616161;
  --vscode-editorGroup-dropIntoPromptBackground: #f3f3f3;
  --vscode-sideBySideEditor-horizontalBorder: #e7e7e7;
  --vscode-sideBySideEditor-verticalBorder: #e7e7e7;
  --vscode-panel-background: #fafafa;
  --vscode-panel-border: rgba(128, 128, 128, 0.35);
  --vscode-panelTitle-activeForeground: #424242;
  --vscode-panelTitle-inactiveForeground: rgba(66, 66, 66, 0.75);
  --vscode-panelTitle-activeBorder: #424242;
  --vscode-panelInput-border: #dddddd;
  --vscode-panel-dropBorder: #424242;
  --vscode-panelSection-dropBackground: rgba(38, 119, 203, 0.18);
  --vscode-panelSectionHeader-background: rgba(128, 128, 128, 0.2);
  --vscode-panelSection-border: rgba(128, 128, 128, 0.35);
  --vscode-banner-background: #004386;
  --vscode-banner-foreground: #ffffff;
  --vscode-banner-iconForeground: #1a85ff;
  --vscode-statusBar-foreground: #ffffff;
  --vscode-statusBar-noFolderForeground: #ffffff;
  --vscode-statusBar-background: #007acc;
  --vscode-statusBar-noFolderBackground: #68217a;
  --vscode-statusBar-focusBorder: #ffffff;
  --vscode-statusBarItem-activeBackground: rgba(255, 255, 255, 0.18);
  --vscode-statusBarItem-focusBorder: #ffffff;
  --vscode-statusBarItem-hoverBackground: rgba(255, 255, 255, 0.12);
  --vscode-statusBarItem-compactHoverBackground: rgba(255, 255, 255, 0.2);
  --vscode-statusBarItem-prominentForeground: #ffffff;
  --vscode-statusBarItem-prominentBackground: rgba(0, 0, 0, 0.5);
  --vscode-statusBarItem-prominentHoverBackground: rgba(0, 0, 0, 0.3);
  --vscode-statusBarItem-errorBackground: #611708;
  --vscode-statusBarItem-errorForeground: #ffffff;
  --vscode-statusBarItem-warningBackground: #725102;
  --vscode-statusBarItem-warningForeground: #ffffff;
  --vscode-activityBar-background: #2c2c2c;
  --vscode-activityBar-foreground: #ffffff;
  --vscode-activityBar-inactiveForeground: rgba(255, 255, 255, 0.4);
  --vscode-activityBar-activeBorder: #ffffff;
  --vscode-activityBar-dropBorder: #ffffff;
  --vscode-activityBarBadge-background: #007acc;
  --vscode-activityBarBadge-foreground: #ffffff;
  --vscode-statusBarItem-remoteBackground: #007acc;
  --vscode-statusBarItem-remoteForeground: #ffffff;
  --vscode-extensionBadge-remoteBackground: #007acc;
  --vscode-extensionBadge-remoteForeground: #ffffff;
  --vscode-sideBar-background: #f3f3f3;
  --vscode-sideBar-dropBackground: rgba(38, 119, 203, 0.18);
  --vscode-sideBarSectionHeader-background: rgba(128, 128, 128, 0.2);
  --vscode-titleBar-activeForeground: #333333;
  --vscode-titleBar-inactiveForeground: rgba(51, 51, 51, 0.6);
  --vscode-titleBar-activeBackground: #dddddd;
  --vscode-titleBar-inactiveBackground: rgba(221, 221, 221, 0.6);
  --vscode-menubar-selectionForeground: #333333;
  --vscode-menubar-selectionBackground: rgba(184, 184, 184, 0.31);
  --vscode-notifications-foreground: #616161;
  --vscode-notifications-background: #f3f3f3;
  --vscode-notificationLink-foreground: #006ab1;
  --vscode-notificationCenterHeader-background: #e7e7e7;
  --vscode-notifications-border: #e7e7e7;
  --vscode-notificationsErrorIcon-foreground: #e51400;
  --vscode-notificationsWarningIcon-foreground: #bf8803;
  --vscode-notificationsInfoIcon-foreground: #1a85ff;
  --vscode-commandCenter-foreground: #333333;
  --vscode-commandCenter-activeForeground: #333333;
  --vscode-commandCenter-activeBackground: rgba(184, 184, 184, 0.31);
  --vscode-commandCenter-border: rgba(128, 128, 128, 0.35);
  --vscode-editorCommentsWidget-resolvedBorder: rgba(97, 97, 97, 0.5);
  --vscode-editorCommentsWidget-unresolvedBorder: #1a85ff;
  --vscode-editorCommentsWidget-rangeBackground: rgba(26, 133, 255, 0.1);
  --vscode-editorCommentsWidget-rangeBorder: rgba(26, 133, 255, 0.4);
  --vscode-editorCommentsWidget-rangeActiveBackground: rgba(26, 133, 255, 0.1);
  --vscode-editorCommentsWidget-rangeActiveBorder: rgba(26, 133, 255, 0.4);
  --vscode-editorGutter-commentRangeForeground: #d5d8e9;
  --vscode-debugToolBar-background: #f3f3f3;
  --vscode-debugIcon-startForeground: #388a34;
  --vscode-editor-stackFrameHighlightBackground: rgba(255, 255, 102, 0.45);
  --vscode-editor-focusedStackFrameHighlightBackground: rgba(206, 231, 206, 0.45);
  --vscode-mergeEditor-change\.background: rgba(155, 185, 85, 0.2);
  --vscode-mergeEditor-change\.word\.background: rgba(156, 204, 44, 0.4);
  --vscode-mergeEditor-conflict\.unhandledUnfocused\.border: rgba(255, 166, 0, 0.48);
  --vscode-mergeEditor-conflict\.unhandledFocused\.border: #ffa600;
  --vscode-mergeEditor-conflict\.handledUnfocused\.border: rgba(134, 134, 134, 0.29);
  --vscode-mergeEditor-conflict\.handledFocused\.border: rgba(193, 193, 193, 0.8);
  --vscode-mergeEditor-conflict\.handled\.minimapOverViewRuler: rgba(173, 172, 168, 0.93);
  --vscode-mergeEditor-conflict\.unhandled\.minimapOverViewRuler: #fcba03;
  --vscode-mergeEditor-conflictingLines\.background: rgba(255, 234, 0, 0.28);
  --vscode-settings-headerForeground: #444444;
  --vscode-settings-modifiedItemIndicator: #66afe0;
  --vscode-settings-headerBorder: rgba(128, 128, 128, 0.35);
  --vscode-settings-sashBorder: rgba(128, 128, 128, 0.35);
  --vscode-settings-dropdownBackground: #ffffff;
  --vscode-settings-dropdownBorder: #cecece;
  --vscode-settings-dropdownListBorder: #c8c8c8;
  --vscode-settings-checkboxBackground: #ffffff;
  --vscode-settings-checkboxBorder: #cecece;
  --vscode-settings-textInputBackground: #ffffff;
  --vscode-settings-textInputForeground: #616161;
  --vscode-settings-numberInputBackground: #ffffff;
  --vscode-settings-numberInputForeground: #616161;
  --vscode-settings-focusedRowBackground: rgba(240, 240, 240, 0.6);
  --vscode-settings-rowHoverBackground: rgba(240, 240, 240, 0.3);
  --vscode-settings-focusedRowBorder: rgba(0, 0, 0, 0.12);
  --vscode-terminal-foreground: #333333;
  --vscode-terminal-selectionBackground: #ccc9ad;
  --vscode-terminal-inactiveSelectionBackground: rgba(204, 201, 173, 0.5);
  --vscode-terminalCommandDecoration-defaultBackground: rgba(0, 0, 0, 0.25);
  --vscode-terminalCommandDecoration-successBackground: #2090d3;
  --vscode-terminalCommandDecoration-errorBackground: #e51400;
  --vscode-terminalOverviewRuler-cursorForeground: rgba(160, 160, 160, 0.8);
  --vscode-terminal-border: rgba(128, 128, 128, 0.35);
  --vscode-terminal-findMatchBackground: #a8ac94;
  --vscode-terminal-findMatchHighlightBackground: rgba(234, 92, 0, 0.33);
  --vscode-terminalOverviewRuler-findMatchForeground: rgba(209, 134, 22, 0.49);
  --vscode-terminal-dropBackground: rgba(38, 119, 203, 0.18);
  --vscode-testing-iconFailed: #f14c4c;
  --vscode-testing-iconErrored: #f14c4c;
  --vscode-testing-iconPassed: #73c991;
  --vscode-testing-runAction: #73c991;
  --vscode-testing-iconQueued: #cca700;
  --vscode-testing-iconUnset: #848484;
  --vscode-testing-iconSkipped: #848484;
  --vscode-testing-peekBorder: #e51400;
  --vscode-testing-peekHeaderBackground: rgba(229, 20, 0, 0.1);
  --vscode-testing-message\.error\.decorationForeground: #e51400;
  --vscode-testing-message\.error\.lineBackground: rgba(255, 0, 0, 0.2);
  --vscode-testing-message\.info\.decorationForeground: rgba(51, 51, 51, 0.5);
  --vscode-welcomePage-tileBackground: #f3f3f3;
  --vscode-welcomePage-tileHoverBackground: #dbdbdb;
  --vscode-welcomePage-tileShadow: rgba(0, 0, 0, 0.16);
  --vscode-welcomePage-progress\.background: #ffffff;
  --vscode-welcomePage-progress\.foreground: #006ab1;
  --vscode-debugExceptionWidget-border: #a31515;
  --vscode-debugExceptionWidget-background: #f1dfde;
  --vscode-ports-iconRunningProcessForeground: #007acc;
  --vscode-statusBar-debuggingBackground: #cc6633;
  --vscode-statusBar-debuggingForeground: #ffffff;
  --vscode-editor-inlineValuesForeground: rgba(0, 0, 0, 0.5);
  --vscode-editor-inlineValuesBackground: rgba(255, 200, 0, 0.2);
  --vscode-editorGutter-modifiedBackground: #2090d3;
  --vscode-editorGutter-addedBackground: #48985d;
  --vscode-editorGutter-deletedBackground: #e51400;
  --vscode-minimapGutter-modifiedBackground: #2090d3;
  --vscode-minimapGutter-addedBackground: #48985d;
  --vscode-minimapGutter-deletedBackground: #e51400;
  --vscode-editorOverviewRuler-modifiedForeground: rgba(32, 144, 211, 0.6);
  --vscode-editorOverviewRuler-addedForeground: rgba(72, 152, 93, 0.6);
  --vscode-editorOverviewRuler-deletedForeground: rgba(229, 20, 0, 0.6);
  --vscode-debugIcon-breakpointForeground: #e51400;
  --vscode-debugIcon-breakpointDisabledForeground: #848484;
  --vscode-debugIcon-breakpointUnverifiedForeground: #848484;
  --vscode-debugIcon-breakpointCurrentStackframeForeground: #be8700;
  --vscode-debugIcon-breakpointStackframeForeground: #89d185;
  --vscode-notebook-cellBorderColor: #e4e6f1;
  --vscode-notebook-focusedEditorBorder: #0090f1;
  --vscode-notebookStatusSuccessIcon-foreground: #388a34;
  --vscode-notebookStatusErrorIcon-foreground: #a1260d;
  --vscode-notebookStatusRunningIcon-foreground: #616161;
  --vscode-notebook-cellToolbarSeparator: rgba(128, 128, 128, 0.35);
  --vscode-notebook-selectedCellBackground: #e4e6f1;
  --vscode-notebook-selectedCellBorder: #e4e6f1;
  --vscode-notebook-focusedCellBorder: #0090f1;
  --vscode-notebook-inactiveFocusedCellBorder: #e4e6f1;
  --vscode-notebook-cellStatusBarItemHoverBackground: rgba(0, 0, 0, 0.08);
  --vscode-notebook-cellInsertionIndicator: #0090f1;
  --vscode-notebookScrollbarSlider-background: rgba(100, 100, 100, 0.4);
  --vscode-notebookScrollbarSlider-hoverBackground: rgba(100, 100, 100, 0.7);
  --vscode-notebookScrollbarSlider-activeBackground: rgba(0, 0, 0, 0.6);
  --vscode-notebook-symbolHighlightBackground: rgba(253, 255, 0, 0.2);
  --vscode-notebook-cellEditorBackground: #f3f3f3;
  --vscode-notebook-editorBackground: #fafafa;
  --vscode-keybindingTable-headerBackground: rgba(97, 97, 97, 0.04);
  --vscode-keybindingTable-rowsBackground: rgba(97, 97, 97, 0.04);
  --vscode-scm-providerBorder: #c8c8c8;
  --vscode-debugTokenExpression-name: #9b46b0;
  --vscode-debugTokenExpression-value: rgba(108, 108, 108, 0.8);
  --vscode-debugTokenExpression-string: #a31515;
  --vscode-debugTokenExpression-boolean: #0000ff;
  --vscode-debugTokenExpression-number: #098658;
  --vscode-debugTokenExpression-error: #e51400;
  --vscode-debugView-exceptionLabelForeground: #ffffff;
  --vscode-debugView-exceptionLabelBackground: #a31515;
  --vscode-debugView-stateLabelForeground: #616161;
  --vscode-debugView-stateLabelBackground: rgba(136, 136, 136, 0.27);
  --vscode-debugView-valueChangedHighlight: #569cd6;
  --vscode-debugConsole-infoForeground: #1a85ff;
  --vscode-debugConsole-warningForeground: #bf8803;
  --vscode-debugConsole-errorForeground: #a1260d;
  --vscode-debugConsole-sourceForeground: #616161;
  --vscode-debugConsoleInputIcon-foreground: #616161;
  --vscode-debugIcon-pauseForeground: #007acc;
  --vscode-debugIcon-stopForeground: #a1260d;
  --vscode-debugIcon-disconnectForeground: #a1260d;
  --vscode-debugIcon-restartForeground: #388a34;
  --vscode-debugIcon-stepOverForeground: #007acc;
  --vscode-debugIcon-stepIntoForeground: #007acc;
  --vscode-debugIcon-stepOutForeground: #007acc;
  --vscode-debugIcon-continueForeground: #007acc;
  --vscode-debugIcon-stepBackForeground: #007acc;
  --vscode-extensionButton-prominentBackground: #007acc;
  --vscode-extensionButton-prominentForeground: #ffffff;
  --vscode-extensionButton-prominentHoverBackground: #0062a3;
  --vscode-extensionIcon-starForeground: #df6100;
  --vscode-extensionIcon-verifiedForeground: #006ab1;
  --vscode-extensionIcon-preReleaseForeground: #1d9271;
  --vscode-extensionIcon-sponsorForeground: #b51e78;
  --vscode-terminal-ansiBlack: #000000;
  --vscode-terminal-ansiRed: #cd3131;
  --vscode-terminal-ansiGreen: #00bc00;
  --vscode-terminal-ansiYellow: #949800;
  --vscode-terminal-ansiBlue: #0451a5;
  --vscode-terminal-ansiMagenta: #bc05bc;
  --vscode-terminal-ansiCyan: #0598bc;
  --vscode-terminal-ansiWhite: #555555;
  --vscode-terminal-ansiBrightBlack: #666666;
  --vscode-terminal-ansiBrightRed: #cd3131;
  --vscode-terminal-ansiBrightGreen: #14ce14;
  --vscode-terminal-ansiBrightYellow: #b5ba00;
  --vscode-terminal-ansiBrightBlue: #0451a5;
  --vscode-terminal-ansiBrightMagenta: #bc05bc;
  --vscode-terminal-ansiBrightCyan: #0598bc;
  --vscode-terminal-ansiBrightWhite: #a5a5a5;
  --vscode-interactive-activeCodeBorder: #1a85ff;
  --vscode-interactive-inactiveCodeBorder: #e4e6f1;
  --vscode-gitDecoration-addedResourceForeground: #587c0c;
  --vscode-gitDecoration-modifiedResourceForeground: #895503;
  --vscode-gitDecoration-deletedResourceForeground: #ad0707;
  --vscode-gitDecoration-renamedResourceForeground: #007100;
  --vscode-gitDecoration-untrackedResourceForeground: #007100;
  --vscode-gitDecoration-ignoredResourceForeground: #8e8e90;
  --vscode-gitDecoration-stageModifiedResourceForeground: #895503;
  --vscode-gitDecoration-stageDeletedResourceForeground: #ad0707;
  --vscode-gitDecoration-conflictingResourceForeground: #ad0707;
  --vscode-gitDecoration-submoduleResourceForeground: #1258a7;
}

.dark {
  --vscode-font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  --vscode-font-weight: normal;
  --vscode-font-size: 13px;
  --vscode-editor-font-family: Menlo, Monaco, "Courier New", monospace;
  --vscode-editor-font-weight: normal;
  --vscode-editor-font-size: 12px;
  --vscode-foreground: #adbac7;
  --vscode-disabledForeground: rgba(204, 204, 204, 0.5);
  --vscode-errorForeground: #e5534b;
  --vscode-descriptionForeground: #768390;
  --vscode-icon-foreground: #768390;
  --vscode-focusBorder: #316dca;
  --vscode-textSeparator-foreground: #373e47;
  --vscode-textLink-foreground: #539bf5;
  --vscode-textLink-activeForeground: #539bf5;
  --vscode-textPreformat-foreground: #768390;
  --vscode-textBlockQuote-background: #1c2128;
  --vscode-textBlockQuote-border: #444c56;
  --vscode-textCodeBlock-background: rgba(99, 110, 123, 0.4);
  --vscode-widget-shadow: rgba(0, 0, 0, 0.36);
  --vscode-input-background: #22272e;
  --vscode-input-foreground: #adbac7;
  --vscode-input-border: #444c56;
  --vscode-inputOption-activeBorder: rgba(0, 122, 204, 0);
  --vscode-inputOption-hoverBackground: rgba(90, 93, 94, 0.5);
  --vscode-inputOption-activeBackground: rgba(49, 109, 202, 0.4);
  --vscode-inputOption-activeForeground: #ffffff;
  --vscode-input-placeholderForeground: #545d68;
  --vscode-inputValidation-infoBackground: #063b49;
  --vscode-inputValidation-infoBorder: #007acc;
  --vscode-inputValidation-warningBackground: #352a05;
  --vscode-inputValidation-warningBorder: #b89500;
  --vscode-inputValidation-errorBackground: #5a1d1d;
  --vscode-inputValidation-errorBorder: #be1100;
  --vscode-dropdown-background: #2d333b;
  --vscode-dropdown-listBackground: #2d333b;
  --vscode-dropdown-foreground: #adbac7;
  --vscode-dropdown-border: #444c56;
  --vscode-checkbox-background: #2d333b;
  --vscode-checkbox-foreground: #adbac7;
  --vscode-checkbox-border: #444c56;
  --vscode-button-foreground: #ffffff;
  --vscode-button-separator: rgba(255, 255, 255, 0.4);
  --vscode-button-background: #347d39;
  --vscode-button-hoverBackground: #46954a;
  --vscode-button-secondaryForeground: #adbac7;
  --vscode-button-secondaryBackground: #3d444d;
  --vscode-button-secondaryHoverBackground: #444c56;
  --vscode-badge-background: #316dca;
  --vscode-badge-foreground: #cdd9e5;
  --vscode-scrollbar-shadow: rgba(84, 93, 104, 0.2);
  --vscode-scrollbarSlider-background: rgba(99, 110, 123, 0.2);
  --vscode-scrollbarSlider-hoverBackground: rgba(99, 110, 123, 0.27);
  --vscode-scrollbarSlider-activeBackground: rgba(99, 110, 123, 0.53);
  --vscode-progressBar-background: #316dca;
  --vscode-editorError-foreground: #f14c4c;
  --vscode-editorWarning-foreground: #cca700;
  --vscode-editorInfo-foreground: #3794ff;
  --vscode-editorHint-foreground: rgba(238, 238, 238, 0.7);
  --vscode-sash-hoverBorder: #316dca;
  --vscode-editor-background: #22272e;
  --vscode-editor-foreground: #adbac7;
  --vscode-editorStickyScroll-background: #22272e;
  --vscode-editorStickyScrollHover-background: #2a2d2e;
  --vscode-editorWidget-background: #2d333b;
  --vscode-editorWidget-foreground: #adbac7;
  --vscode-editorWidget-border: #454545;
  --vscode-quickInput-background: #2d333b;
  --vscode-quickInput-foreground: #adbac7;
  --vscode-quickInputTitle-background: rgba(255, 255, 255, 0.1);
  --vscode-pickerGroup-foreground: #768390;
  --vscode-pickerGroup-border: #444c56;
  --vscode-keybindingLabel-background: rgba(128, 128, 128, 0.17);
  --vscode-keybindingLabel-foreground: #adbac7;
  --vscode-keybindingLabel-border: rgba(51, 51, 51, 0.6);
  --vscode-keybindingLabel-bottomBorder: rgba(68, 68, 68, 0.6);
  --vscode-editor-selectionBackground: #264f78;
  --vscode-editor-inactiveSelectionBackground: rgba(38, 79, 120, 0.5);
  --vscode-editor-selectionHighlightBackground: rgba(87, 171, 90, 0.25);
  --vscode-editor-findMatchBackground: #966600;
  --vscode-editor-findMatchHighlightBackground: rgba(234, 197, 95, 0.5);
  --vscode-editor-findRangeHighlightBackground: rgba(58, 61, 65, 0.4);
  --vscode-searchEditor-findMatchBackground: rgba(234, 197, 95, 0.33);
  --vscode-editor-hoverHighlightBackground: rgba(38, 79, 120, 0.25);
  --vscode-editorHoverWidget-background: #2d333b;
  --vscode-editorHoverWidget-foreground: #adbac7;
  --vscode-editorHoverWidget-border: #454545;
  --vscode-editorHoverWidget-statusBarBackground: #363d47;
  --vscode-editorLink-activeForeground: #4e94ce;
  --vscode-editorInlayHint-foreground: #768390;
  --vscode-editorInlayHint-background: rgba(118, 131, 144, 0.2);
  --vscode-editorInlayHint-typeForeground: #768390;
  --vscode-editorInlayHint-typeBackground: rgba(118, 131, 144, 0.2);
  --vscode-editorInlayHint-parameterForeground: #768390;
  --vscode-editorInlayHint-parameterBackground: rgba(118, 131, 144, 0.2);
  --vscode-editorLightBulb-foreground: #ffcc00;
  --vscode-editorLightBulbAutoFix-foreground: #75beff;
  --vscode-diffEditor-insertedTextBackground: rgba(52, 125, 57, 0.3);
  --vscode-diffEditor-removedTextBackground: rgba(201, 60, 55, 0.3);
  --vscode-diffEditor-insertedLineBackground: rgba(52, 125, 57, 0.2);
  --vscode-diffEditor-removedLineBackground: rgba(201, 60, 55, 0.2);
  --vscode-diffEditor-diagonalFill: rgba(204, 204, 204, 0.2);
  --vscode-list-focusBackground: rgba(65, 132, 228, 0.15);
  --vscode-list-focusForeground: #adbac7;
  --vscode-list-focusOutline: #316dca;
  --vscode-list-activeSelectionBackground: rgba(99, 110, 123, 0.4);
  --vscode-list-activeSelectionForeground: #adbac7;
  --vscode-list-inactiveSelectionBackground: rgba(99, 110, 123, 0.4);
  --vscode-list-inactiveSelectionForeground: #adbac7;
  --vscode-list-inactiveFocusBackground: rgba(65, 132, 228, 0.15);
  --vscode-list-hoverBackground: rgba(99, 110, 123, 0.1);
  --vscode-list-hoverForeground: #adbac7;
  --vscode-list-dropBackground: #062f4a;
  --vscode-list-highlightForeground: #539bf5;
  --vscode-list-focusHighlightForeground: #539bf5;
  --vscode-list-invalidItemForeground: #b89500;
  --vscode-list-errorForeground: #f88070;
  --vscode-list-warningForeground: #cca700;
  --vscode-listFilterWidget-background: #2d333b;
  --vscode-listFilterWidget-outline: rgba(0, 0, 0, 0);
  --vscode-listFilterWidget-noMatchesOutline: #be1100;
  --vscode-listFilterWidget-shadow: rgba(0, 0, 0, 0.36);
  --vscode-list-filterMatchBackground: rgba(234, 197, 95, 0.5);
  --vscode-tree-indentGuidesStroke: #373e47;
  --vscode-tree-tableColumnsBorder: rgba(204, 204, 204, 0.13);
  --vscode-tree-tableOddRowsBackground: rgba(173, 186, 199, 0.04);
  --vscode-list-deemphasizedForeground: #8c8c8c;
  --vscode-quickInputList-focusForeground: #adbac7;
  --vscode-quickInputList-focusBackground: rgba(99, 110, 123, 0.4);
  --vscode-menu-foreground: #adbac7;
  --vscode-menu-background: #2d333b;
  --vscode-menu-selectionForeground: #adbac7;
  --vscode-menu-selectionBackground: rgba(99, 110, 123, 0.4);
  --vscode-menu-separatorBackground: #606060;
  --vscode-toolbar-hoverBackground: rgba(90, 93, 94, 0.31);
  --vscode-toolbar-activeBackground: rgba(99, 102, 103, 0.31);
  --vscode-editor-snippetTabstopHighlightBackground: rgba(124, 124, 124, 0.3);
  --vscode-editor-snippetFinalTabstopHighlightBorder: #525252;
  --vscode-breadcrumb-foreground: #768390;
  --vscode-breadcrumb-background: #22272e;
  --vscode-breadcrumb-focusForeground: #adbac7;
  --vscode-breadcrumb-activeSelectionForeground: #768390;
  --vscode-breadcrumbPicker-background: #2d333b;
  --vscode-merge-currentHeaderBackground: rgba(64, 200, 174, 0.5);
  --vscode-merge-currentContentBackground: rgba(64, 200, 174, 0.2);
  --vscode-merge-incomingHeaderBackground: rgba(64, 166, 255, 0.5);
  --vscode-merge-incomingContentBackground: rgba(64, 166, 255, 0.2);
  --vscode-merge-commonHeaderBackground: rgba(96, 96, 96, 0.4);
  --vscode-merge-commonContentBackground: rgba(96, 96, 96, 0.16);
  --vscode-editorOverviewRuler-currentContentForeground: rgba(64, 200, 174, 0.5);
  --vscode-editorOverviewRuler-incomingContentForeground: rgba(64, 166, 255, 0.5);
  --vscode-editorOverviewRuler-commonContentForeground: rgba(96, 96, 96, 0.4);
  --vscode-editorOverviewRuler-findMatchForeground: rgba(209, 134, 22, 0.49);
  --vscode-editorOverviewRuler-selectionHighlightForeground: rgba(160, 160, 160, 0.8);
  --vscode-minimap-findMatchHighlight: #d18616;
  --vscode-minimap-selectionOccurrenceHighlight: #676767;
  --vscode-minimap-selectionHighlight: #264f78;
  --vscode-minimap-errorHighlight: rgba(255, 18, 18, 0.7);
  --vscode-minimap-warningHighlight: #cca700;
  --vscode-minimap-foregroundOpacity: #000000;
  --vscode-minimapSlider-background: rgba(99, 110, 123, 0.1);
  --vscode-minimapSlider-hoverBackground: rgba(99, 110, 123, 0.14);
  --vscode-minimapSlider-activeBackground: rgba(99, 110, 123, 0.27);
  --vscode-problemsErrorIcon-foreground: #f14c4c;
  --vscode-problemsWarningIcon-foreground: #cca700;
  --vscode-problemsInfoIcon-foreground: #3794ff;
  --vscode-charts-foreground: #adbac7;
  --vscode-charts-lines: rgba(173, 186, 199, 0.5);
  --vscode-charts-red: #f14c4c;
  --vscode-charts-blue: #3794ff;
  --vscode-charts-yellow: #cca700;
  --vscode-charts-orange: #d18616;
  --vscode-charts-green: #89d185;
  --vscode-charts-purple: #b180d7;
  --vscode-editor-lineHighlightBackground: rgba(99, 110, 123, 0.1);
  --vscode-editor-lineHighlightBorder: #282828;
  --vscode-editor-rangeHighlightBackground: rgba(255, 255, 255, 0.04);
  --vscode-editor-symbolHighlightBackground: rgba(234, 197, 95, 0.5);
  --vscode-editorCursor-foreground: #539bf5;
  --vscode-editorWhitespace-foreground: #545d68;
  --vscode-editorIndentGuide-background: rgba(173, 186, 199, 0.12);
  --vscode-editorIndentGuide-activeBackground: rgba(173, 186, 199, 0.24);
  --vscode-editorLineNumber-foreground: #636e7b;
  --vscode-editorActiveLineNumber-foreground: #c6c6c6;
  --vscode-editorLineNumber-activeForeground: #adbac7;
  --vscode-editorRuler-foreground: #5a5a5a;
  --vscode-editorCodeLens-foreground: #999999;
  --vscode-editorBracketMatch-background: rgba(87, 171, 90, 0.25);
  --vscode-editorBracketMatch-border: rgba(87, 171, 90, 0.6);
  --vscode-editorOverviewRuler-border: #1c2128;
  --vscode-editorGutter-background: #22272e;
  --vscode-editorUnnecessaryCode-opacity: rgba(0, 0, 0, 0.67);
  --vscode-editorGhostText-foreground: rgba(255, 255, 255, 0.34);
  --vscode-editorOverviewRuler-rangeHighlightForeground: rgba(0, 122, 204, 0.6);
  --vscode-editorOverviewRuler-errorForeground: rgba(255, 18, 18, 0.7);
  --vscode-editorOverviewRuler-warningForeground: #cca700;
  --vscode-editorOverviewRuler-infoForeground: #3794ff;
  --vscode-editorBracketHighlight-foreground1: #6cb6ff;
  --vscode-editorBracketHighlight-foreground2: #6bc46d;
  --vscode-editorBracketHighlight-foreground3: #daaa3f;
  --vscode-editorBracketHighlight-foreground4: #ff938a;
  --vscode-editorBracketHighlight-foreground5: #fc8dc7;
  --vscode-editorBracketHighlight-foreground6: #dcbdfb;
  --vscode-editorBracketHighlight-unexpectedBracket\.foreground: #768390;
  --vscode-editorBracketPairGuide-background1: rgba(0, 0, 0, 0);
  --vscode-editorBracketPairGuide-background2: rgba(0, 0, 0, 0);
  --vscode-editorBracketPairGuide-background3: rgba(0, 0, 0, 0);
  --vscode-editorBracketPairGuide-background4: rgba(0, 0, 0, 0);
  --vscode-editorBracketPairGuide-background5: rgba(0, 0, 0, 0);
  --vscode-editorBracketPairGuide-background6: rgba(0, 0, 0, 0);
  --vscode-editorBracketPairGuide-activeBackground1: rgba(0, 0, 0, 0);
  --vscode-editorBracketPairGuide-activeBackground2: rgba(0, 0, 0, 0);
  --vscode-editorBracketPairGuide-activeBackground3: rgba(0, 0, 0, 0);
  --vscode-editorBracketPairGuide-activeBackground4: rgba(0, 0, 0, 0);
  --vscode-editorBracketPairGuide-activeBackground5: rgba(0, 0, 0, 0);
  --vscode-editorBracketPairGuide-activeBackground6: rgba(0, 0, 0, 0);
  --vscode-editorUnicodeHighlight-border: #bd9b03;
  --vscode-editorUnicodeHighlight-background: rgba(189, 155, 3, 0.15);
  --vscode-symbolIcon-arrayForeground: #e0823d;
  --vscode-symbolIcon-booleanForeground: #539bf5;
  --vscode-symbolIcon-classForeground: #e0823d;
  --vscode-symbolIcon-colorForeground: #6cb6ff;
  --vscode-symbolIcon-constantForeground: #adbac7;
  --vscode-symbolIcon-constructorForeground: #dcbdfb;
  --vscode-symbolIcon-enumeratorForeground: #e0823d;
  --vscode-symbolIcon-enumeratorMemberForeground: #539bf5;
  --vscode-symbolIcon-eventForeground: #636e7b;
  --vscode-symbolIcon-fieldForeground: #e0823d;
  --vscode-symbolIcon-fileForeground: #c69026;
  --vscode-symbolIcon-folderForeground: #c69026;
  --vscode-symbolIcon-functionForeground: #b083f0;
  --vscode-symbolIcon-interfaceForeground: #e0823d;
  --vscode-symbolIcon-keyForeground: #539bf5;
  --vscode-symbolIcon-keywordForeground: #f47067;
  --vscode-symbolIcon-methodForeground: #b083f0;
  --vscode-symbolIcon-moduleForeground: #f47067;
  --vscode-symbolIcon-namespaceForeground: #f47067;
  --vscode-symbolIcon-nullForeground: #539bf5;
  --vscode-symbolIcon-numberForeground: #57ab5a;
  --vscode-symbolIcon-objectForeground: #e0823d;
  --vscode-symbolIcon-operatorForeground: #6cb6ff;
  --vscode-symbolIcon-packageForeground: #e0823d;
  --vscode-symbolIcon-propertyForeground: #e0823d;
  --vscode-symbolIcon-referenceForeground: #539bf5;
  --vscode-symbolIcon-snippetForeground: #539bf5;
  --vscode-symbolIcon-stringForeground: #6cb6ff;
  --vscode-symbolIcon-structForeground: #e0823d;
  --vscode-symbolIcon-textForeground: #6cb6ff;
  --vscode-symbolIcon-typeParameterForeground: #6cb6ff;
  --vscode-symbolIcon-unitForeground: #539bf5;
  --vscode-symbolIcon-variableForeground: #e0823d;
  --vscode-editorHoverWidget-highlightForeground: #539bf5;
  --vscode-editorOverviewRuler-bracketMatchForeground: #a0a0a0;
  --vscode-editor-foldBackground: rgba(99, 110, 123, 0.1);
  --vscode-editorGutter-foldingControlForeground: #768390;
  --vscode-editor-linkedEditingBackground: rgba(83, 155, 245, 0.07);
  --vscode-editor-wordHighlightBackground: rgba(99, 110, 123, 0.5);
  --vscode-editor-wordHighlightStrongBackground: rgba(99, 110, 123, 0.3);
  --vscode-editor-wordHighlightBorder: rgba(99, 110, 123, 0.6);
  --vscode-editor-wordHighlightStrongBorder: rgba(99, 110, 123, 0.6);
  --vscode-editorOverviewRuler-wordHighlightForeground: rgba(160, 160, 160, 0.8);
  --vscode-editorOverviewRuler-wordHighlightStrongForeground: rgba(192, 160, 192, 0.8);
  --vscode-peekViewTitle-background: rgba(55, 148, 255, 0.1);
  --vscode-peekViewTitleLabel-foreground: #ffffff;
  --vscode-peekViewTitleDescription-foreground: rgba(204, 204, 204, 0.7);
  --vscode-peekView-border: #3794ff;
  --vscode-peekViewResult-background: #22272e;
  --vscode-peekViewResult-lineForeground: #bbbbbb;
  --vscode-peekViewResult-fileForeground: #ffffff;
  --vscode-peekViewResult-selectionBackground: rgba(51, 153, 255, 0.2);
  --vscode-peekViewResult-selectionForeground: #ffffff;
  --vscode-peekViewEditor-background: rgba(99, 110, 123, 0.1);
  --vscode-peekViewEditorGutter-background: rgba(99, 110, 123, 0.1);
  --vscode-peekViewResult-matchHighlightBackground: rgba(174, 124, 20, 0.4);
  --vscode-peekViewEditor-matchHighlightBackground: rgba(174, 124, 20, 0.4);
  --vscode-editorMarkerNavigationError-background: #f14c4c;
  --vscode-editorMarkerNavigationError-headerBackground: rgba(241, 76, 76, 0.1);
  --vscode-editorMarkerNavigationWarning-background: #cca700;
  --vscode-editorMarkerNavigationWarning-headerBackground: rgba(204, 167, 0, 0.1);
  --vscode-editorMarkerNavigationInfo-background: #3794ff;
  --vscode-editorMarkerNavigationInfo-headerBackground: rgba(55, 148, 255, 0.1);
  --vscode-editorMarkerNavigation-background: #22272e;
  --vscode-editorSuggestWidget-background: #2d333b;
  --vscode-editorSuggestWidget-border: #454545;
  --vscode-editorSuggestWidget-foreground: #adbac7;
  --vscode-editorSuggestWidget-selectedForeground: #adbac7;
  --vscode-editorSuggestWidget-selectedBackground: rgba(99, 110, 123, 0.4);
  --vscode-editorSuggestWidget-highlightForeground: #539bf5;
  --vscode-editorSuggestWidget-focusHighlightForeground: #539bf5;
  --vscode-editorSuggestWidgetStatus-foreground: rgba(173, 186, 199, 0.5);
  --vscode-tab-activeBackground: #22272e;
  --vscode-tab-unfocusedActiveBackground: #22272e;
  --vscode-tab-inactiveBackground: #1c2128;
  --vscode-tab-unfocusedInactiveBackground: #1c2128;
  --vscode-tab-activeForeground: #adbac7;
  --vscode-tab-inactiveForeground: #768390;
  --vscode-tab-unfocusedActiveForeground: rgba(173, 186, 199, 0.5);
  --vscode-tab-unfocusedInactiveForeground: rgba(118, 131, 144, 0.5);
  --vscode-tab-hoverBackground: #22272e;
  --vscode-tab-unfocusedHoverBackground: rgba(99, 110, 123, 0.1);
  --vscode-tab-border: #444c56;
  --vscode-tab-lastPinnedBorder: #373e47;
  --vscode-tab-activeBorder: #22272e;
  --vscode-tab-unfocusedActiveBorder: #22272e;
  --vscode-tab-activeBorderTop: #ec775c;
  --vscode-tab-unfocusedActiveBorderTop: #444c56;
  --vscode-tab-activeModifiedBorder: #3399cc;
  --vscode-tab-inactiveModifiedBorder: rgba(51, 153, 204, 0.5);
  --vscode-tab-unfocusedActiveModifiedBorder: rgba(51, 153, 204, 0.5);
  --vscode-tab-unfocusedInactiveModifiedBorder: rgba(51, 153, 204, 0.25);
  --vscode-editorPane-background: #22272e;
  --vscode-editorGroupHeader-tabsBackground: #1c2128;
  --vscode-editorGroupHeader-tabsBorder: #444c56;
  --vscode-editorGroupHeader-noTabsBackground: #22272e;
  --vscode-editorGroup-border: #444c56;
  --vscode-editorGroup-dropBackground: rgba(83, 89, 93, 0.5);
  --vscode-editorGroup-dropIntoPromptForeground: #adbac7;
  --vscode-editorGroup-dropIntoPromptBackground: #2d333b;
  --vscode-sideBySideEditor-horizontalBorder: #444c56;
  --vscode-sideBySideEditor-verticalBorder: #444c56;
  --vscode-panel-background: #1c2128;
  --vscode-panel-border: #444c56;
  --vscode-panelTitle-activeForeground: #adbac7;
  --vscode-panelTitle-inactiveForeground: #768390;
  --vscode-panelTitle-activeBorder: #ec775c;
  --vscode-panelInput-border: #444c56;
  --vscode-panel-dropBorder: #adbac7;
  --vscode-panelSection-dropBackground: rgba(83, 89, 93, 0.5);
  --vscode-panelSectionHeader-background: rgba(128, 128, 128, 0.2);
  --vscode-panelSection-border: #444c56;
  --vscode-banner-background: rgba(99, 110, 123, 0.4);
  --vscode-banner-foreground: #adbac7;
  --vscode-banner-iconForeground: #3794ff;
  --vscode-statusBar-foreground: #768390;
  --vscode-statusBar-noFolderForeground: #768390;
  --vscode-statusBar-background: #22272e;
  --vscode-statusBar-noFolderBackground: #22272e;
  --vscode-statusBar-border: #444c56;
  --vscode-statusBar-focusBorder: rgba(49, 109, 202, 0.5);
  --vscode-statusBar-noFolderBorder: #444c56;
  --vscode-statusBarItem-activeBackground: rgba(173, 186, 199, 0.12);
  --vscode-statusBarItem-focusBorder: #316dca;
  --vscode-statusBarItem-hoverBackground: rgba(173, 186, 199, 0.08);
  --vscode-statusBarItem-compactHoverBackground: rgba(255, 255, 255, 0.2);
  --vscode-statusBarItem-prominentForeground: #768390;
  --vscode-statusBarItem-prominentBackground: rgba(99, 110, 123, 0.4);
  --vscode-statusBarItem-prominentHoverBackground: rgba(0, 0, 0, 0.3);
  --vscode-statusBarItem-errorBackground: #a01e17;
  --vscode-statusBarItem-errorForeground: #ffffff;
  --vscode-statusBarItem-warningBackground: #7a6400;
  --vscode-statusBarItem-warningForeground: #ffffff;
  --vscode-activityBar-background: #22272e;
  --vscode-activityBar-foreground: #adbac7;
  --vscode-activityBar-inactiveForeground: #768390;
  --vscode-activityBar-border: #444c56;
  --vscode-activityBar-activeBorder: #ec775c;
  --vscode-activityBar-dropBorder: #adbac7;
  --vscode-activityBarBadge-background: #316dca;
  --vscode-activityBarBadge-foreground: #cdd9e5;
  --vscode-statusBarItem-remoteBackground: #444c56;
  --vscode-statusBarItem-remoteForeground: #adbac7;
  --vscode-extensionBadge-remoteBackground: #316dca;
  --vscode-extensionBadge-remoteForeground: #cdd9e5;
  --vscode-sideBar-background: #1c2128;
  --vscode-sideBar-foreground: #adbac7;
  --vscode-sideBar-border: #444c56;
  --vscode-sideBarTitle-foreground: #adbac7;
  --vscode-sideBar-dropBackground: rgba(83, 89, 93, 0.5);
  --vscode-sideBarSectionHeader-background: #1c2128;
  --vscode-sideBarSectionHeader-foreground: #adbac7;
  --vscode-sideBarSectionHeader-border: #444c56;
  --vscode-titleBar-activeForeground: #768390;
  --vscode-titleBar-inactiveForeground: #768390;
  --vscode-titleBar-activeBackground: #22272e;
  --vscode-titleBar-inactiveBackground: #1c2128;
  --vscode-titleBar-border: #444c56;
  --vscode-menubar-selectionForeground: #768390;
  --vscode-menubar-selectionBackground: rgba(90, 93, 94, 0.31);
  --vscode-notifications-foreground: #adbac7;
  --vscode-notifications-background: #2d333b;
  --vscode-notificationLink-foreground: #539bf5;
  --vscode-notificationCenterHeader-foreground: #768390;
  --vscode-notificationCenterHeader-background: #2d333b;
  --vscode-notifications-border: #444c56;
  --vscode-notificationsErrorIcon-foreground: #e5534b;
  --vscode-notificationsWarningIcon-foreground: #c69026;
  --vscode-notificationsInfoIcon-foreground: #539bf5;
  --vscode-commandCenter-foreground: #768390;
  --vscode-commandCenter-activeForeground: #768390;
  --vscode-commandCenter-activeBackground: rgba(90, 93, 94, 0.31);
  --vscode-commandCenter-border: #444c56;
  --vscode-editorCommentsWidget-resolvedBorder: rgba(204, 204, 204, 0.5);
  --vscode-editorCommentsWidget-unresolvedBorder: #3794ff;
  --vscode-editorCommentsWidget-rangeBackground: rgba(55, 148, 255, 0.1);
  --vscode-editorCommentsWidget-rangeBorder: rgba(55, 148, 255, 0.4);
  --vscode-editorCommentsWidget-rangeActiveBackground: rgba(55, 148, 255, 0.1);
  --vscode-editorCommentsWidget-rangeActiveBorder: rgba(55, 148, 255, 0.4);
  --vscode-editorGutter-commentRangeForeground: rgba(99, 110, 123, 0.4);
  --vscode-debugToolBar-background: #2d333b;
  --vscode-debugIcon-startForeground: #89d185;
  --vscode-editor-stackFrameHighlightBackground: rgba(174, 124, 20, 0.4);
  --vscode-editor-focusedStackFrameHighlightBackground: rgba(70, 149, 74, 0.4);
  --vscode-mergeEditor-change\.background: rgba(155, 185, 85, 0.2);
  --vscode-mergeEditor-change\.word\.background: rgba(156, 204, 44, 0.2);
  --vscode-mergeEditor-conflict\.unhandledUnfocused\.border: rgba(255, 166, 0, 0.48);
  --vscode-mergeEditor-conflict\.unhandledFocused\.border: #ffa600;
  --vscode-mergeEditor-conflict\.handledUnfocused\.border: rgba(134, 134, 134, 0.29);
  --vscode-mergeEditor-conflict\.handledFocused\.border: rgba(193, 193, 193, 0.8);
  --vscode-mergeEditor-conflict\.handled\.minimapOverViewRuler: rgba(173, 172, 168, 0.93);
  --vscode-mergeEditor-conflict\.unhandled\.minimapOverViewRuler: #fcba03;
  --vscode-mergeEditor-conflictingLines\.background: rgba(255, 234, 0, 0.28);
  --vscode-settings-headerForeground: #768390;
  --vscode-settings-modifiedItemIndicator: rgba(174, 124, 20, 0.4);
  --vscode-settings-headerBorder: #444c56;
  --vscode-settings-sashBorder: #444c56;
  --vscode-settings-dropdownBackground: #2d333b;
  --vscode-settings-dropdownForeground: #adbac7;
  --vscode-settings-dropdownBorder: #444c56;
  --vscode-settings-dropdownListBorder: #454545;
  --vscode-settings-checkboxBackground: #2d333b;
  --vscode-settings-checkboxForeground: #adbac7;
  --vscode-settings-checkboxBorder: #444c56;
  --vscode-settings-textInputBackground: #22272e;
  --vscode-settings-textInputForeground: #adbac7;
  --vscode-settings-textInputBorder: #444c56;
  --vscode-settings-numberInputBackground: #22272e;
  --vscode-settings-numberInputForeground: #adbac7;
  --vscode-settings-numberInputBorder: #444c56;
  --vscode-settings-focusedRowBackground: rgba(99, 110, 123, 0.06);
  --vscode-settings-rowHoverBackground: rgba(99, 110, 123, 0.03);
  --vscode-settings-focusedRowBorder: rgba(255, 255, 255, 0.12);
  --vscode-terminal-foreground: #adbac7;
  --vscode-terminal-selectionBackground: #264f78;
  --vscode-terminal-inactiveSelectionBackground: rgba(38, 79, 120, 0.5);
  --vscode-terminalCommandDecoration-defaultBackground: rgba(255, 255, 255, 0.25);
  --vscode-terminalCommandDecoration-successBackground: #1b81a8;
  --vscode-terminalCommandDecoration-errorBackground: #f14c4c;
  --vscode-terminalOverviewRuler-cursorForeground: rgba(160, 160, 160, 0.8);
  --vscode-terminal-border: #444c56;
  --vscode-terminal-findMatchBackground: #966600;
  --vscode-terminal-findMatchHighlightBackground: rgba(234, 197, 95, 0.5);
  --vscode-terminalOverviewRuler-findMatchForeground: rgba(209, 134, 22, 0.49);
  --vscode-terminal-dropBackground: rgba(83, 89, 93, 0.5);
  --vscode-terminal-tab\.activeBorder: #22272e;
  --vscode-testing-iconFailed: #f14c4c;
  --vscode-testing-iconErrored: #f14c4c;
  --vscode-testing-iconPassed: #73c991;
  --vscode-testing-runAction: #73c991;
  --vscode-testing-iconQueued: #cca700;
  --vscode-testing-iconUnset: #848484;
  --vscode-testing-iconSkipped: #848484;
  --vscode-testing-peekBorder: #f14c4c;
  --vscode-testing-peekHeaderBackground: rgba(241, 76, 76, 0.1);
  --vscode-testing-message\.error\.decorationForeground: #f14c4c;
  --vscode-testing-message\.error\.lineBackground: rgba(255, 0, 0, 0.2);
  --vscode-testing-message\.info\.decorationForeground: rgba(173, 186, 199, 0.5);
  --vscode-welcomePage-tileBackground: #2d333b;
  --vscode-welcomePage-tileHoverBackground: #363d47;
  --vscode-welcomePage-tileShadow: rgba(0, 0, 0, 0.36);
  --vscode-welcomePage-progress\.background: #22272e;
  --vscode-welcomePage-progress\.foreground: #539bf5;
  --vscode-debugExceptionWidget-border: #a31515;
  --vscode-debugExceptionWidget-background: #420b0d;
  --vscode-ports-iconRunningProcessForeground: #444c56;
  --vscode-statusBar-debuggingBackground: #c93c37;
  --vscode-statusBar-debuggingForeground: #cdd9e5;
  --vscode-statusBar-debuggingBorder: #444c56;
  --vscode-editor-inlineValuesForeground: rgba(255, 255, 255, 0.5);
  --vscode-editor-inlineValuesBackground: rgba(255, 200, 0, 0.2);
  --vscode-editorGutter-modifiedBackground: rgba(174, 124, 20, 0.4);
  --vscode-editorGutter-addedBackground: rgba(70, 149, 74, 0.4);
  --vscode-editorGutter-deletedBackground: rgba(229, 83, 75, 0.4);
  --vscode-minimapGutter-modifiedBackground: rgba(174, 124, 20, 0.4);
  --vscode-minimapGutter-addedBackground: rgba(70, 149, 74, 0.4);
  --vscode-minimapGutter-deletedBackground: rgba(229, 83, 75, 0.4);
  --vscode-editorOverviewRuler-modifiedForeground: rgba(174, 124, 20, 0.24);
  --vscode-editorOverviewRuler-addedForeground: rgba(70, 149, 74, 0.24);
  --vscode-editorOverviewRuler-deletedForeground: rgba(229, 83, 75, 0.24);
  --vscode-debugIcon-breakpointForeground: #e5534b;
  --vscode-debugIcon-breakpointDisabledForeground: #848484;
  --vscode-debugIcon-breakpointUnverifiedForeground: #848484;
  --vscode-debugIcon-breakpointCurrentStackframeForeground: #ffcc00;
  --vscode-debugIcon-breakpointStackframeForeground: #89d185;
  --vscode-notebook-cellBorderColor: rgba(99, 110, 123, 0.4);
  --vscode-notebook-focusedEditorBorder: #316dca;
  --vscode-notebookStatusSuccessIcon-foreground: #89d185;
  --vscode-notebookStatusErrorIcon-foreground: #e5534b;
  --vscode-notebookStatusRunningIcon-foreground: #adbac7;
  --vscode-notebook-cellToolbarSeparator: rgba(128, 128, 128, 0.35);
  --vscode-notebook-selectedCellBackground: rgba(99, 110, 123, 0.4);
  --vscode-notebook-selectedCellBorder: rgba(99, 110, 123, 0.4);
  --vscode-notebook-focusedCellBorder: #316dca;
  --vscode-notebook-inactiveFocusedCellBorder: rgba(99, 110, 123, 0.4);
  --vscode-notebook-cellStatusBarItemHoverBackground: rgba(255, 255, 255, 0.15);
  --vscode-notebook-cellInsertionIndicator: #316dca;
  --vscode-notebookScrollbarSlider-background: rgba(99, 110, 123, 0.2);
  --vscode-notebookScrollbarSlider-hoverBackground: rgba(99, 110, 123, 0.27);
  --vscode-notebookScrollbarSlider-activeBackground: rgba(99, 110, 123, 0.53);
  --vscode-notebook-symbolHighlightBackground: rgba(255, 255, 255, 0.04);
  --vscode-notebook-cellEditorBackground: #1c2128;
  --vscode-notebook-editorBackground: #22272e;
  --vscode-keybindingTable-headerBackground: rgba(173, 186, 199, 0.04);
  --vscode-keybindingTable-rowsBackground: rgba(173, 186, 199, 0.04);
  --vscode-scm-providerBorder: #454545;
  --vscode-searchEditor-textInputBorder: #444c56;
  --vscode-debugTokenExpression-name: #6cb6ff;
  --vscode-debugTokenExpression-value: #96d0ff;
  --vscode-debugTokenExpression-string: #96d0ff;
  --vscode-debugTokenExpression-boolean: #6bc46d;
  --vscode-debugTokenExpression-number: #6bc46d;
  --vscode-debugTokenExpression-error: #ff938a;
  --vscode-debugView-exceptionLabelForeground: #adbac7;
  --vscode-debugView-exceptionLabelBackground: #6c2022;
  --vscode-debugView-stateLabelForeground: #adbac7;
  --vscode-debugView-stateLabelBackground: rgba(136, 136, 136, 0.27);
  --vscode-debugView-valueChangedHighlight: #569cd6;
  --vscode-debugConsole-infoForeground: #768390;
  --vscode-debugConsole-warningForeground: #c69026;
  --vscode-debugConsole-errorForeground: #ff938a;
  --vscode-debugConsole-sourceForeground: #daaa3f;
  --vscode-debugConsoleInputIcon-foreground: #b083f0;
  --vscode-debugIcon-pauseForeground: #75beff;
  --vscode-debugIcon-stopForeground: #f48771;
  --vscode-debugIcon-disconnectForeground: #f48771;
  --vscode-debugIcon-restartForeground: #89d185;
  --vscode-debugIcon-stepOverForeground: #75beff;
  --vscode-debugIcon-stepIntoForeground: #75beff;
  --vscode-debugIcon-stepOutForeground: #75beff;
  --vscode-debugIcon-continueForeground: #75beff;
  --vscode-debugIcon-stepBackForeground: #75beff;
  --vscode-extensionButton-prominentBackground: #347d39;
  --vscode-extensionButton-prominentForeground: #ffffff;
  --vscode-extensionButton-prominentHoverBackground: #46954a;
  --vscode-extensionIcon-starForeground: #ff8e00;
  --vscode-extensionIcon-verifiedForeground: #539bf5;
  --vscode-extensionIcon-preReleaseForeground: #1d9271;
  --vscode-extensionIcon-sponsorForeground: #d758b3;
  --vscode-terminal-ansiBlack: #545d68;
  --vscode-terminal-ansiRed: #f47067;
  --vscode-terminal-ansiGreen: #57ab5a;
  --vscode-terminal-ansiYellow: #c69026;
  --vscode-terminal-ansiBlue: #539bf5;
  --vscode-terminal-ansiMagenta: #b083f0;
  --vscode-terminal-ansiCyan: #39c5cf;
  --vscode-terminal-ansiWhite: #909dab;
  --vscode-terminal-ansiBrightBlack: #636e7b;
  --vscode-terminal-ansiBrightRed: #ff938a;
  --vscode-terminal-ansiBrightGreen: #6bc46d;
  --vscode-terminal-ansiBrightYellow: #daaa3f;
  --vscode-terminal-ansiBrightBlue: #6cb6ff;
  --vscode-terminal-ansiBrightMagenta: #dcbdfb;
  --vscode-terminal-ansiBrightCyan: #56d4dd;
  --vscode-terminal-ansiBrightWhite: #cdd9e5;
  --vscode-interactive-activeCodeBorder: #3794ff;
  --vscode-interactive-inactiveCodeBorder: rgba(99, 110, 123, 0.4);
  --vscode-gitDecoration-addedResourceForeground: #57ab5a;
  --vscode-gitDecoration-modifiedResourceForeground: #c69026;
  --vscode-gitDecoration-deletedResourceForeground: #e5534b;
  --vscode-gitDecoration-renamedResourceForeground: #73c991;
  --vscode-gitDecoration-untrackedResourceForeground: #57ab5a;
  --vscode-gitDecoration-ignoredResourceForeground: #545d68;
  --vscode-gitDecoration-stageModifiedResourceForeground: #e2c08d;
  --vscode-gitDecoration-stageDeletedResourceForeground: #c74e39;
  --vscode-gitDecoration-conflictingResourceForeground: #cc6b2c;
  --vscode-gitDecoration-submoduleResourceForeground: #768390;
}
