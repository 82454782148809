.easy-edit-inline-wrapper {
  @apply flex flex-row w-full;
}

.easy-edit-component-wrapper {
  @apply w-full;
}

.easy-edit-textarea {
  min-height: auto !important;
  @apply px-0 py-0 border-none shadow-none outline-none focus:shadow-none;
}

/*
.easy-edit-wrapper {
  @apply px-2 space-x-2 truncate vmd:px-4 whitespace-nowrap;
} */

/* .easy-edit-button-wrapper {
  @apply flex-row-centered justify-end;
  display: flex !important;
} */
