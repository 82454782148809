@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root,
#root > div {
  @apply h-full p-0 m-0 mx-auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.icon {
  @apply opacity-100 cursor-pointer hover:opacity-80 text-icon-fg;
}

.popDownItem {
  @apply flex flex-row items-center p-1 space-x-2 cursor-pointer text-menu-fg hover:bg-menu-selection-bg hover:text-menu-selection-fg;
}

.link {
  @apply font-medium cursor-pointer text-link-fg hover:underline;
}

.flex-row-centered {
  @apply flex flex-row items-center space-x-1;
}
