.switch-wrapper {
  position: relative;
  height: 100% !important;
  width: 100% !important;
}

.switch-wrapper > div {
  position: absolute;
  height: 100% !important;
  width: 100% !important;
}
